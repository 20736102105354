import React from "react";
import { connect } from "react-redux";
import "../../../assets/css/superAdmin.css";
import { CSmartTable, CBadge, CListGroup } from '@coreui/react-pro' // Assuming you've installed the CoreUI package
import { Link } from "react-router-dom";

import * as userActions from "../../../actions/index";
import "react-toastify/dist/ReactToastify.css";
import Moment from 'react-moment';
import Logout from "../../../components/SuperAdmin/Logout";

class Complaints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [], // Add other state variables if 
            complaints: []
        };
    }
    componentWillMount() {

        this.props.getComplaints().then((response) => {
            if (response.success === true) {
                this.setState({
                    complaints: response.data
                })
            } else {
                console.log("error")
            }
        });
    }



    render() {
        // var details = [];
        const columns = [
            {
                label: '#',
                key: 'index',
                filter: false,
                sorter: false,
            },
            {
                key: 'full_name',
                label: 'Complaint By',
            },
            {
                key: 'email',
            },
            {
                key: "subject",
            },
            {
                key: 'description',
            },
            {
                key: 'date',
            },
            {
                key: 'status',
            },
            {
                key: 'show_details',
                label: 'Action',
                filter: false,
                sorter: false,
            },

        ]
        const getBadge = (status) => {
            switch (status) {
                case 'Active':
                    return 'success'
                case 'Inactive':
                    return 'secondary'
                case 'Pending':
                    return 'warning'
                case 'closed':
                    return 'danger'
                default:
                    return 'primary'
            }
        }
        const { complaints } = this.state;



        // const style = {
        //     width: '45%'
        // }
        return (
            <>
                <React.Fragment>

                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">Manage Complaints</span>
                            </div>
                            <div>
                                <Logout />
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="sales-boxes">
                                <div className="recent-sales box">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9">
                                            <div className="title ">Manage Complaints</div>
                                        </div>

                                    </div>

                                    <CSmartTable
                                        activePage={1}
                                        cleaner
                                        clickableRows
                                        columns={columns}
                                        columnFilter
                                        columnSorter
                                        footer
                                        items={complaints}
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        pagination
                                        onFilteredItemsChange={(items) => {
                                            console.log(items)
                                        }}
                                        onSelectedItemsChange={(items) => {
                                            console.log(items)
                                        }}
                                        scopedColumns={{
                                            index: (item, index) => {
                                                return (
                                                    <td>{index + 1}</td>
                                                )
                                            },
                                            subject: (item) => (
                                                <td>
                                                    {item.subject?.length > 30 ? item.subject.slice(0, 30).concat('..') : item.subject}
                                                </td>
                                            ),
                                            description: (item) => (
                                                <td>
                                                    {item.description?.length > 30 ? item.description.slice(0, 30).concat('..') : item.description}
                                                </td>
                                            ),
                                            date: (item) => {
                                                return (<td>
                                                    <Moment format="DD/MM/YYYY">{item.created_at}</Moment>
                                                </td>)
                                            },
                                            status: (item) => {
                                                return (
                                                    <td>
                                                        <CBadge color={getBadge(item.isreply === '0' ? "Inactive" : item.isreply === '1' && item.status === "active" ? "Pending" : "Active")}>
                                                            {item.isreply === '0' ? "Pending" : item.isreply === '1' && item.status === "active" ? "in-progress" : "Resolved"}
                                                        </CBadge>
                                                    </td>
                                                )
                                            },

                                            show_details: (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <div className="d-flex">
                                                            <Link
                                                                to={`/super-admin-view-complaint/${btoa(item.id)}`}
                                                                className="btn btn-outline-primary btn-sm square me-3 rounded-circle"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="square"
                                                                size="sm"
                                                            >
                                                                <i className="fa fa-eye"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                )
                                            },
                                        }}
                                        // selectable
                                        sorterValue={{ column: 'status', state: 'asc' }}
                                        tableFilter
                                        tableProps={{
                                            className: 'add-this-class',
                                            responsive: true,
                                            striped: true,
                                            hover: true,
                                        }}
                                        tableBodyProps={{
                                            className: 'align-middle'
                                        }}
                                    />

                                </div>

                            </div>
                        </div>
                    </section>

                </React.Fragment>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}


const mapDispatchToProps = {
    getComplaints: userActions.getComplaints,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Complaints);