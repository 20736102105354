import axios from "axios";
import * as actionType from "../constants/orderActionType.js";

export const UsersList = (response) => {
    return {
        error: null,
        loader: false,
        // type: actionType.GET_COMPLETE_ORDERS_SUCCESS,
        response: response
    };
};
export const GetUsers = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            url: `${actionType.environment_url}/get-get-user-list`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
}
export const CreateUser = (form_data) => {
    return (dispatch) => {
        console.log({ form_data })
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/create-user`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                console.log(response)
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
} 
export const DeleteUser = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/delete-user`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response;
            })
            .catch(function (error) {
                return error;
            });
    };
} 
export const ViewUser = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/view-user`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
} 

export const UpdateUser = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/update-user`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
} 
export const BlockUser = (form_data) => {
    return () => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/block-user`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
} 
