import * as Master_actionType from '../constants/MasteractionTypes';

const initial_state = {
  error: null,
  token: null,
  loader: false,
  productData: [],
};

const reducer = (state = initial_state, action) => {
  switch (action.type) {
    case Master_actionType.Getproduct_START:
      return {
        ...state,
        loader: true, 
        error: null,
      };
    case Master_actionType.Getproduct_SUCCESS:
      return {
        ...state,
        loader: false,
        error: null,
        productData: action.productData || [],
        token: action.token || null,
      };
    case Master_actionType.Getproduct_FAIL:
      return {
        ...state,
        loader: false,
        error: action.error || 'An error occurred',
      };
    default:
      return state;
  }
};

export default reducer;
