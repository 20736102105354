import React, { useState } from 'react';
import axios from './../../../axios';
import Logout from '../../../components/SuperAdmin/Logout';

const PromoCode = () => {
    const [couponName, setCouponName] = useState('');
    const [priceOrPercentage, setPriceOrPercentage] = useState('');
    const [validUse, setValidUse] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Validate priceOrPercentage
        const isNumber = /^(\d+(\.\d+)?)$/.test(priceOrPercentage);
        const isPercentage = /^(\d+(\.\d+)?%)$/.test(priceOrPercentage);

        if (!isNumber && !isPercentage) {
            setError('Price or Percentage must be a valid number or percentage (e.g., 10 or 20%)');
            return;
        }

        setError(''); // Clear any previous error

        // Convert priceOrPercentage to a number if it's a percentage
        const value = priceOrPercentage.endsWith('%')
            ? parseFloat(priceOrPercentage) / 100
            : parseFloat(priceOrPercentage);

        const newCoupon = {
            couponName,
            priceOrPercentage: priceOrPercentage.endsWith('%') ? priceOrPercentage : value.toString(),
            validUse,  // Valid use should be a date string in 'YYYY-MM-DD' format
        };

        try {
            const response = await axios.post('/AddCoupon', newCoupon);
            setMessage(response.data.message);
        } catch (error) {
            console.error("Error adding coupon:", error);
            setMessage("Internal Server Error");
        }
    };

    return (
        <section className="home-section">
            <nav>
                <div className="sidebar-button">
                    <i className='bx bx-menu sidebarBtn'></i>
                    <span className="dashboard">Create Promo Code</span>
                </div>
                <div>
                    <Logout />
                </div>
            </nav>

            <div className="home-content">
                <div className="sales-boxes">
                    <div className="recent-sales box">
                        <div className="row">
                            <div className="col-lg-9 col-md-9">
                                <div className="title">Add Coupon</div>
                            </div>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="mb-3">
                                <label htmlFor="couponName" className="form-label">Coupon Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="couponName"
                                    value={couponName}
                                    onChange={(e) => setCouponName(e.target.value)}
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="priceOrPercentage" className="form-label">Price or Percentage</label>
                                <input
                                    type="text"
                                    step="0.01"
                                    className="form-control"
                                    id="priceOrPercentage"
                                    value={priceOrPercentage}
                                    onChange={(e) => setPriceOrPercentage(e.target.value)}
                                />
                                {error && <p className="text-danger">{error}</p>}
                            </div>
                            <div className="mb-3">
                                <label htmlFor="validUse" className="form-label">Valid Use</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="validUse"
                                    value={validUse}
                                    onChange={(e) => setValidUse(e.target.value)}
                                />
                            </div>
                            <button type="submit" className="btn btn-primary">Submit</button>
                        </form>
                        {message && <p className="mt-3">{message}</p>}
                    </div>
                </div>
            </div>
        </section>
    );
};

export default PromoCode;
