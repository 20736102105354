import React from "react";
import { connect } from "react-redux";
import axios from './../../axios';
import * as Actions from "../../actions";
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import "../../assets/css/profile.css";
import * as userActions from "../../actions/index";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'; // Import eye icons
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

export const Profile = ({ setEmail, setUserPicture }) => {
    const { user, isAuthenticated, isLoading } = useAuth0();

    useEffect(() => {
        document.body.classList.remove('dashboard-open');
        if (isAuthenticated && user) {
            if (typeof setEmail === 'function') {
                setEmail(user.email);
            }
            if (typeof setUserPicture === 'function') {
                setUserPicture(user.picture);
            }
        }
    }, [isAuthenticated, user, setEmail, setUserPicture]);

    return (
        isAuthenticated && (
            <div>
                {/* <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p> */}
            </div>
        )
    );
};

class Account extends React.Component {
    constructor(props) {
        super(props);
        console.log("props", props)
        this.state = {
            user_info: null,
            userEmail: "",
            userPhoneNo: "",
            userPicture: "",
            billing_form: {
                first_name: { value: "" },
                last_name: { value: "" },
                email: { value: "" },
                phone_no: { value: "" },
                address1: { value: "" },
                address2: { value: "" },
                country: { value: "" },
                state: { value: "" },
                zip: { value: "" },
                id: { value: "" },
            },
            profileImg: null, // Add state for the profile image
        };
    }

    setUserPicture = (picture) => {
        this.setState({ userPicture: picture });
    }

    setEmail = (email) => {
        this.setState({ userEmail: email }, () => {
            console.log('Email set in state:', this.state.userEmail); // Ensure email is set
        });
    }

    async componentDidMount() {
        try {
            this.setState({ website: "http://www.taobao.com/" });

            // Fetch cart and wishlist data
            const cartResponse = await this.props.onCart();
            const wishlistResponse = await this.props.onWishlist();

            // Update state with cart and wishlist data
            this.setState({
                cartItemCount: cartResponse.data?.length,
                whishListItemCount: wishlistResponse?.length,
                user_info: cartResponse.user_info || wishlistResponse?.user_info,
                loading: false,
            });

            // Update billing form with user information
            if (cartResponse.user_info) {
                const user = cartResponse.user_info;
                const [firstName, lastName] = user.full_name.split(" ");

                this.setState({
                    billing_form: {
                        ...this.state.billing_form,
                        first_name: { value: firstName || "" },
                        last_name: { value: lastName || "" },
                        email: { value: user.email || "" },
                        phone_no: { value: user.phone_no || "" },
                        address1: { value: user.mailing_address || "" },
                        id: { value: user.id || "" }
                    },
                    userPicture: user.profile_img ? `${process.env.REACT_APP_API_URL}/uploads/${user.profile_img}` : "",                 });
            }

            if (this.props?.location?.state?.search_input) {
                this.setState({
                    search_input: this.props?.location?.state?.search_input,
                });
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    }

    // Handle input change
    handleInputChange = (e) => {
        const { id, value } = e.target;
        this.setState(prevState => ({
            billing_form: {
                ...prevState.billing_form,
                [id]: { value }
            }
        }));
    }

    // Handle file input change
    handleFileChange = (e) => {
        this.setState({ profileImg: e.target.files[0] });
    }

    handleUpdateProfile = async () => {
        const { billing_form, profileImg } = this.state;
        const formData = new FormData();
        formData.append('full_name', `${billing_form.first_name.value} ${billing_form.last_name.value}`);
        formData.append('email', billing_form.email.value);
        formData.append('phone', billing_form.phone_no.value);
        formData.append('id', billing_form.id.value);
        formData.append('address1', billing_form.address1.value);

        if (profileImg) {
            formData.append('profile_img', profileImg);
        }

        try {
            const response = await axios.post(`update-profile`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.data.success) {
                const updatedUserInfo = response.data.user_info;

                this.setState({
                    user_info: updatedUserInfo,
                    userEmail: updatedUserInfo.email,
                    userPhoneNo: updatedUserInfo.phone_no,
                    userPicture: updatedUserInfo.profile_img || this.state.userPicture, 
                    billing_form: {
                        ...this.state.billing_form,
                        email: { value: updatedUserInfo.email },
                        phone_no: { value: updatedUserInfo.phone_no },
                        first_name: { value: updatedUserInfo.full_name.split(" ")[0] },
                        last_name: { value: updatedUserInfo.full_name.split(" ")[1] },
                        id: { value: updatedUserInfo.id }
                    },
                    profileImg: null 
                });

                toast.success("Profile updated successfully");
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error("There was an error updating the profile!", error);
            toast.error("An error occurred while updating the profile. Please try again.");
        }
    }



    render() {
        const { billing_form, profileImg } = this.state;

        return (
            <>
                <React.Fragment>
                    
                    <Profile setEmail={this.setEmail} setUserPicture={this.setUserPicture} />
                    <ToastContainer position={toast.POSITION.TOP_RIGHT} />
                    <div className="profile-container container-xl px-4 mt-4">
                        <nav className="nav nav-borders">
                            <a className="nav-link active ms-0" href="" target="">Profile</a>
                        </nav>
                        <hr className="mt-0 mb-4" />
                        <div className="row">
                            <div className="col-xl-4">
                                <div className="card mb-4 mb-xl-0">
                                    <div className="card-header">Profile Picture</div>
                                    <div className="card-body text-center" style={{ padding: '55px' }}>
                                        <img
                                            className="img-account-profile rounded-circle mb-2"
                                            src={profileImg ? URL.createObjectURL(profileImg) : this.state.userPicture}
                                            alt="Profile"
                                        />


                                        <div className="small font-italic text-muted mb-4">JPG or PNG no larger than 5 MB</div>
                                        <input
                                            type="file"
                                            className="form-control"
                                            name="profile_img"
                                            onChange={this.handleFileChange}
                                        />
                                        <button
                                            className="btn btn-primary mt-3"
                                            type="button"
                                            onClick={this.handleUpdateProfile}
                                        >
                                            Upload new image
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8">
                                <div className="card mb-4">
                                    <div className="card-header">Account Details</div>
                                    <div className="card-body">
                                        <form>
                                            <div className="row gx-3 mb-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="first_name">First name</label>
                                                    <input
                                                        className="form-control"
                                                        id="first_name"
                                                        type="text"
                                                        placeholder="Enter your first name"
                                                        value={billing_form.first_name.value}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="last_name">Last name</label>
                                                    <input
                                                        className="form-control"
                                                        id="last_name"
                                                        type="text"
                                                        placeholder="Enter your last name"
                                                        value={billing_form.last_name.value}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row gx-3 mb-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="email">Email address</label>
                                                    <input
                                                        className="form-control"
                                                        id="email"
                                                        type="email"
                                                        placeholder="Enter your email address"
                                                        value={billing_form.email.value}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="phone_no">Phone number</label>
                                                    <input
                                                        className="form-control"
                                                        id="phone_no"
                                                        type="tel"
                                                        placeholder="Enter your phone number"
                                                        value={billing_form.phone_no.value}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row gx-3 mb-3">
                                                <div className="col-md-6">
                                                    <label className="small mb-1" htmlFor="email">Mailing address</label>
                                                    <input
                                                        className="form-control"
                                                        id="address1"
                                                        type="text"
                                                        placeholder="Enter your mailing address"
                                                        value={billing_form.address1.value}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                className="btn btn-primary mb-3"
                                                type="button"
                                                style={{ float: 'right' }}
                                                onClick={this.handleUpdateProfile}
                                            >
                                                Save changes
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </React.Fragment>
            </>
        );
    }

}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
        user: state.Auth.user,
    };
}

const mapDispatchToProps = {
    onGetAuthUser: userActions.GetAuthUser,
    onCart: Actions.GetCart,
    onWishlist: Actions.GetWishlist,
    ShippingCost: Actions.ShippingCost,
    onCheckoutSub: Actions.OrderCreate,
    onAddress: Actions.AddAddress,
    getAddress: Actions.GetAddress,
    onState: Actions.getState,
    getDeliveryMods: Actions.GetDeliveryModes,
    onGetproduct: userActions.Getproduct,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(Account);
