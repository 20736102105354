import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";

import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createStore, combineReducers, applyMiddleware, compose } from "redux";

import App from "./App";
import AuthReducer from "./reducers/Auth";
import ProductReducer from "./reducers/Product";
import AccountReducer from "./reducers/Account";
import OrderReducer from "./reducers/Orders";
import { Auth0Provider } from '@auth0/auth0-react';
import * as actionType from "./constants/actionTypes";

import reportWebVitals from "./reportWebVitals";


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    Auth: AuthReducer,
    Product: ProductReducer,
    Wishlist: ProductReducer,
    Account: AccountReducer,
    Order: OrderReducer,
});
export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunkMiddleware)));

const domain = actionType.OAuthDomain;
const clientId = actionType.OAuthClientId;
const redirectUri = actionType.OAuthRedirectUri;

// console.log({ domain, clientId, redirectUri })

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <BrowserRouter>
                <Auth0Provider
                    domain={domain}
                    clientId={clientId}
                    redirectUri={redirectUri}
                >
                    <App />
                </Auth0Provider>
            </BrowserRouter>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
