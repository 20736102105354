import React from "react";
import { Link } from "react-router-dom";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";

class HowToBuy extends React.Component {
    componentDidMount() {
        window.scrollTo(0, 0);
    }
    render() {
        return (
            <React.Fragment>
                <div className="">
                    
                    <div className="top-content contact-bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="main-heading">
                                        <h1>Contact us</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="contact-sec">
                        <div className="container-fluid">
                            <div className="row align-items-center">
                                {/* <ContactUsForm /> */}
                                <div className="col-lg-7 col-md-12">
                                    <div className="contact-text">
                                        <h2>Solution you’ve been Looking For</h2>
                                        <span>
                                            Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of
                                            type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.
                                        </span>

                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard</p>
                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    <i className="fa fa-map-marker"></i>3403 Dawson Drive, Little Rock AR,Arkansas - 72002
                                                </Link>
                                            </li>
                                            <li>
                                                <Link todo="#">
                                                    <i className="fa fa-phone"></i>501-847-2649, 501-779-2598
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    <i className="fa fa-envelope"></i>nxiqi@temporary-mail.net
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </React.Fragment>
        );
    }
}

export default HowToBuy;
