import * as actionType from "../constants/actionTypes";

const initial_state = {
    error: null,
    token: null,
    loader: false,
    user: null,
};

const reducer = (state = initial_state, action) => {
    const { type, error, loader, token, user } = action;

    // Match action type for different scenarios
    if (type.endsWith("_START")) {
        return {
            ...state,
            error: null, // Reset error on a new request
            loader: true,
        };
    }

    if (type.endsWith("_SUCCESS")) {
        return {
            ...state,
            error: null,
            loader: false,
            token: token || state.token, // Update token if provided
            user: user || state.user,   // Update user if provided
        };
    }

    if (type.endsWith("_FAIL")) {
        return {
            ...state,
            loader: false,
            error: error, // Update error from the action
        };
    }

    // Default state
    return state;
};

export default reducer;
