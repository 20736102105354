import { useAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react'
import { connect } from 'react-redux'

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const onLogout = () => {

    // logout({ returnTo: window.location.origin });
    localStorage.removeItem("shiporfly_super_admin_authtoken");
    logout();
  };


  return (
    <button className="btn btn-outline-primary" onClick={onLogout}>
      Sign Out
    </button>
  );
};

export class Logout extends Component {
  constructor(props) {
    console.log('props', props)
    super(props);
    this.state = {

    };

  }

  render() {
    return (
      <>
        <div className='logout-btn'>
          <LogoutButton />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => ({})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Logout)