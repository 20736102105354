import React from "react";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../actions/orders";
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import "../../assets/css/orders.css"
import defaultImage from "../../assets/static images/Default-welcomer.png";
import Moment from 'react-moment';
import ReactPaginate from 'react-paginate';



class CompletedOrders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            completedOrders: [],
            currentPage: 1,
            isPushPage: true,
            isNextPage: false,
            urlParamPageNo: 1,
            pageCount: 0
        };
    }
    componentDidMount() {
        document.body.classList.remove('dashboard-open');
        const { currentPage } = this.state;

        const pageNo = new URLSearchParams(window.location.search).get('page') || currentPage;
        console.log({ pageNo })
        this.setState({
            urlParamPageNo: parseInt(pageNo)

        })
        this.props.getComputedOrders({ page: pageNo }).then((response) => {
            console.log(response?.response)
            if (response.success === true) {
                if (response?.response) {
                    this.setState({
                        completedOrders: response?.response?.completedOrders,
                        currentPage: currentPage,
                        isNextPage: false,
                        pageCount: response?.response?.totalPages
                    });
                    // document.location.search = `?page=${urlPageno}`
                }
            }

        }).catch((error) => console.log(error));
    }




    handlePageChange = (event) => {
        this.setState({ currentPage: event.selected, isNextPage: true });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });

        this.props.getComputedOrders({ page: event.selected + 1 }).then((response) => {
            if (response.success === true) {
                if (response?.response) {
                    this.setState({
                        completedOrders: response?.response?.completedOrders,
                        isNextPage: false
                    });
                }
            }

        }).catch((error) => console.log(error));
    }
    render() {
        const { completedOrders, currentPage, isNextPage, urlParamPageNo, pageCount } = this.state;

        return (

            <>
                <React.Fragment>
                    {isNextPage ? <Navigate to={`/completed-orders?page=${currentPage + 1}`} /> : null}
                    <div className="">
                        
                        <div className="checkout-template">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title">
                                            <h1 className="d-flex">Completed Orders</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="item-list">
                                            {completedOrders?.length > 0 &&
                                                <>
                                                    <ul className="row">
                                                        {completedOrders.map((item, index) => {
                                                            return (
                                                                <li className="col-lg-4 col-md-6 col-sm-12 py-3 col-xl-3" key={index}>
                                                                    <Link to={`/view-order-details?${btoa(item.id)}`} className="card">
                                                                        <img src={item.order_items[0].productJson.item.pic_url} className="card__image" alt="" />
                                                                        <div className="card__overlay">
                                                                            <div className="card__header">

                                                                                <div className="card__header-text">
                                                                                    <p className="card__title text-start text-white">{item.order_items[0].productJson.item.title}</p>
                                                                                    <p className="text-danger">
                                                                                        {item.status}
                                                                                    </p>
                                                                                    <span className="card__status"> <Moment format='LL'>{item.created_at}</Moment></span>
                                                                                </div>
                                                                                <h6 className="text-white"> ${item.totalprice}</h6>
                                                                            </div>
                                                                            <p className="card__description">
                                                                                <div className="mb-2">
                                                                                    <a className="text-white" href="#">Need Help?</a>
                                                                                </div>
                                                                                <div>
                                                                                    <a className="text-white" href="#">Report a mistake</a>
                                                                                </div>
                                                                            </p>
                                                                        </div>
                                                                    </Link>
                                                                </li>
                                                            )
                                                        })
                                                        }

                                                    </ul>

                                                    {pageCount > 1 ?
                                                        <div className="mt-2">
                                                            <ReactPaginate
                                                                className=""
                                                                breakLabel="..."
                                                                nextLabel="next >"
                                                                onPageChange={this.handlePageChange}
                                                                pageRangeDisplayed={3}
                                                                marginPagesDisplayed={2}
                                                                pageCount={pageCount}
                                                                previousLabel="< previous"
                                                                pageClassName="page-item"
                                                                pageLinkClassName="page-link"
                                                                previousLinkClassName="page-link"
                                                                nextClassName="page-item"
                                                                nextLinkClassName="page-link"
                                                                breakClassName="page-item"
                                                                breakLinkClassName="page-link"
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                renderOnZeroPageCount={null}
                                                                initialPage={urlParamPageNo - 1}

                                                            />

                                                        </div> : ''
                                                    }
                                                </>
                                            }
                                            {completedOrders?.length === 0 &&
                                                <div className="empty-cart">
                                                    <span>No Record Found</span>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </React.Fragment>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    getComputedOrders: action.GetCompletedOrders,
};
export default connect(mapStateToProps, mapDispatchToProps)(CompletedOrders);
