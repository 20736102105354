import React, { Component } from 'react'
import * as userActions from "../../../actions/index";
import { connect } from 'react-redux';
import Logout from '../../../components/SuperAdmin/Logout';

class Dashboard extends Component {
    constructor(props) {
        super();
        this.state = {
            headerCard: {
                padding: '0px 22px',
                margin: '0 20px',
                'borderRadius': '12px',
                'boxShadow': '0 5px 10px rgba(0, 0, 0, 0.1)'
            },
            counts: {
                user: 0,
                order: 0,
                complaints: 0
            }
        }
    }

    componentDidMount() {
        this.props.Get().then((response) => {
            if (response.success === true) {
                const { user, order, complaints } = response.data;
                this.setState({
                    counts: {
                        user,
                        order,
                        complaints
                    }
                })
            }
        })
    }
    render() {
        const { user, order, complaints } = this.state.counts;
        return (
            <>
                <React.Fragment>
                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">Dashboard</span>
                            </div>
                            <div>
                                <Logout />
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card" style={this.state.headerCard}>
                                        <div className="card-header bg-white">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title flex-grow-1 mb-0">Dashboard </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3 ms-1">
                                    {/* <div className=""> */}

                                    <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Users</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{user}</div>
                                                    </div>
                                                    <div className="col-auto dash-icons">
                                                        <i className="fa fa-users fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Orders</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{order}</div>
                                                    </div>
                                                    <div className="col-auto dash-icons">
                                                        <i className="fa fa-shopping-bag fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-4">
                                        <div className="card border-left-primary shadow h-100 py-2">
                                            <div className="card-body">
                                                <div className="row no-gutters align-items-center">
                                                    <div className="col mr-2">
                                                        <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">Total Complaints</div>
                                                        <div className="h5 mb-0 font-weight-bold text-gray-800">{complaints}</div>
                                                    </div>
                                                    <div className="col-auto dash-icons">
                                                        <i className="fa fa-envelope-open fa-2x text-gray-300"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* </div> */}

                        </div>
                    </section>

                </React.Fragment>
            </>
        )
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    Get: userActions.superAdminDashboard
}
export default connect(mapStateToProps, mapDispatchToProps, null)(Dashboard)
