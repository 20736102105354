import React from "react";
import { connect } from "react-redux";

import Loader from "../../components/Utility/Loader";

import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import Validations from "../../components/Utility/Validations";
import * as action from "../../actions/index";
import defaultImage from "../../assets/static images/Default-welcomer.png";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
class Complaints extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            my_complaints: [],
            illustrationDiv: {
                height: '330px',
                width: '330px',
            },
            illustration: {
                height: '100%',
                width: '100%',
            },
            loader: false,
            complaint_form: {
                email: {
                    type: "email",
                    label: "Email",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "Enter your email",
                    validations: { required: true, email: true },
                },
                subject: {
                    type: "text",
                    label: "Subject",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
                description: {
                    type: "text",
                    label: "Description",
                    value: "",
                    valid: true,
                    error_msg: "",
                    onBlur_out: false,
                    placeholder: "",
                    validations: { required: true },
                },
            },
            complaintProfile: {
                height: '100%',
                width: '100%',
                borderRadius: '50%',
            },
            complaintProfileDiv: {
                height: '30px',
                width: '30px',
            },
            dot: {
                height: '10px',
                width: '10px',
                borderRadius: '50%',
                display: 'inline-block',
            },
            dotDiv: {
                position: 'absolute',
                right: '0'
            }
        };
    }
    componentDidMount() {
        document.body.classList.remove('dashboard-open');
        document.body.style.overflow = 'auto';
        // window.scrollTo(0, 0);
        this.props.my_complaints().then((response) => {
            if (response.success === true) {

                this.setState({
                    my_complaints: response.data,
                })
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
            }
        })
    }

    inputChangeHandler = (event, identifier) => {
        const update_complaint_form = { ...this.state.complaint_form };
        const form_element = { ...update_complaint_form[identifier] };
        form_element.value = event.target.value;
        // Check Validation
        let validate_element = Validations(event, form_element);
        form_element.valid = validate_element.valid;
        form_element.error_msg = validate_element.error_msg;

        update_complaint_form[identifier] = form_element;

        this.setState({ complaint_form: update_complaint_form });
    }
    SubmitHandler = (event) => {
        event.preventDefault();

        let is_form_valid = true;
        const update_complaint_form = this.state.complaint_form;
        for (let key in update_complaint_form) {
            let form_element = update_complaint_form[key];

            let validate_element = Validations(event, form_element);

            is_form_valid = validate_element.valid === false ? false : is_form_valid;
            form_element.onBlur_out = true;
            form_element.valid = validate_element.valid;
            form_element.error_msg = validate_element.error_msg;

            update_complaint_form[key] = form_element;
        }
        this.setState({ complaint_form: update_complaint_form });
        if (is_form_valid) {
            this.setState({
                loader: true
            });
            const form_data = new FormData();
            for (let key in this.state.complaint_form) {
                form_data.append(key, this.state.complaint_form[key].value);
            }
            this.props.createComplaint(form_data).then((response) => {
                if (response.success === true) {
                    toast.success("Complaint sent successfully", { position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {
                        this.setState({
                            loader: false
                        })
                    }, 400)

                } else {
                    toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                    setTimeout(() => {
                        this.setState({
                            loader: false
                        })
                    }, 400)
                }
            })
        }
    }
    render() {
        const { complaint_form, my_complaints, complaintProfileDiv, complaintProfile, dot, dotDiv, html } = this.state;

        return (
            <>
                <React.Fragment>
                    <ToastContainer />
                    {this.state.loader ? <Loader /> : null}
                    {this.props.loader ? <Loader /> : null}
                    <div className="">
                        
                        <div className="top-content about-bg">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="main-heading">
                                            <h1>Complaints</h1>
                                        </div>
                                    </div>
                                    <h3>We are here to assist you!</h3>
                                    <p>Please complete the form below for your complaints.</p>
                                    <hr />
                                    <div className="col-lg-6 complaint-form">
                                        <form className="text-start mx-2" onSubmit={this.SubmitHandler}>
                                            <div className="form-group mb-2">
                                                <label htmlFor="exampleInputEmail1">Email address</label>
                                                <input type={complaint_form.email.type} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={complaint_form.email.placeholder}
                                                    value={complaint_form.email.value ? complaint_form.email.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "email")}
                                                    onBlur={(event) => {
                                                        complaint_form.email.onBlur_out = true;
                                                        this.setState({
                                                            complaint_form: complaint_form,
                                                        });
                                                    }} />
                                                {!complaint_form.email.valid && complaint_form.email.onBlur_out && <div className="error field-error">{complaint_form.email.error_msg}</div>}
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="exampleInputSubject">The complaint is regarding:</label>
                                                <input type={complaint_form.subject.type} name="subject" className="form-control"
                                                    value={complaint_form.subject.value ? complaint_form.subject.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "subject")}
                                                    onBlur={(event) => {
                                                        complaint_form.subject.onBlur_out = true;
                                                        this.setState({
                                                            complaint_form: complaint_form,
                                                        });
                                                    }} />
                                                {!complaint_form.subject.valid && complaint_form.subject.onBlur_out && <div className="error field-error">{complaint_form.subject.error_msg}</div>}
                                            </div>
                                            <div className="form-group mb-2">
                                                <label htmlFor="exampleInputSubject">The specific details of the complaint:</label>
                                                <textarea name="description" type="text" className="form-control" placeholder=""
                                                    value={complaint_form.description.value ? complaint_form.description.value : ""}
                                                    onChange={(event) => this.inputChangeHandler(event, "description")}
                                                    onBlur={(event) => {
                                                        complaint_form.description.onBlur_out = true;
                                                        this.setState({
                                                            complaint_form: complaint_form,
                                                        });
                                                    }}></textarea>
                                                {!complaint_form.description.valid && complaint_form.description.onBlur_out && <div className="error field-error">{complaint_form.description.error_msg}</div>}
                                            </div>
                                            <div className="text-end">
                                                <button type="submit" className="btn btn-primary">Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                    <div className="complaint-illustrations col-lg-6">
                                        <div className="contact1-pic js-tilt bg-white rounded mx-2" data-tilt="" >
                                            <div className="m-auto" style={this.state.illustrationDiv}>
                                                <img style={this.state.illustration} src="/assets/illustrations/complaints.png" alt="IMG" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {my_complaints && my_complaints.length > 0 ?
                                    <div className="container">
                                        <h4 className="mb-3">My complaints</h4>
                                        <div className="">

                                            <marquee>
                                                <div className="d-flex">

                                                    {
                                                        my_complaints.map((item, index) => {
                                                            console.log(item)
                                                            return (
                                                                <div className="col-lg-3 w-25 mx-3" key={index}>
                                                                    <div className="card">
                                                                        <div className="card-header">
                                                                            <div className="d-flex">
                                                                                <div style={complaintProfileDiv} className="flex-shrink-0">
                                                                                    <img src={defaultImage} style={complaintProfile}
                                                                                        alt='' className="img-fluid d-block" />
                                                                                </div>
                                                                                <div className="flex-grow-1 ms-3 text-start">
                                                                                    <h5 className="">
                                                                                        <div className="text-body">{item.full_name}</div>
                                                                                    </h5>
                                                                                    <p className="text-start mb-0">
                                                                                        {item.subject}
                                                                                    </p>

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div style={dotDiv}>
                                                                            <span className={`dot ${item.isreply === "0" ? 'btn btn-secondary' : item.isreply === '1' && item.status==="active"? 'btn btn-warning' : "btn btn-success"} `} style={dot}></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }


                                                </div>
                                            </marquee>
                                        </div>


                                    </div>
                                    : ''}

                            </div>
                        </div>
                        
                    </div>
                </React.Fragment>
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    createComplaint: action.complaint,
    my_complaints: action.my_complaints,
};
export default connect(mapStateToProps, mapDispatchToProps)(Complaints);