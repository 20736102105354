import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";


/*** Checkout Action ***/
export const CheckOutStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_START,
    };
};

export const CheckOutSuccess = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_START,
    };
};

export const CheckOutFail = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.Checkout_START,
    };
};

export const GetDeliveryModes = () => {
    return (dispatch) => {
        // dispatch(GetCurrencyRateStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/get-delivery-modes-by-basket`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                // dispatch(GetCurrencyRateSuccess("Get delivery modes by basket Get !!!"));
                return response.data;
            })
            .catch(function (error) {
                // dispatch(GetCurrencyRateFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const CheckOutCreate = (form_data) => {
    return (dispatch) => {
        dispatch(CheckOutStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/CheckOut`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
        .then(function (response) {
            dispatch(CheckOutSuccess("CheckOut Create successfully !!!"));
            return response.data.data;
        })
        .catch(function (error) {
            dispatch(CheckOutFail("Something went wrong, Please try again."));
            return error;
        });
    };
}

export const getState = (form_data) => {
    return (dispatch) => {
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/get-states`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        }).then(function (response) {
            return response.data;
        }).catch(function (error) {
            return error;
        });
    };
}