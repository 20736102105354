import axios from 'axios';

// Create an Axios instance with a base URL and optional configuration
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Optional: Add request and response interceptors if needed
// apiClient.interceptors.request.use(
//   (config) => {
//     // Add authorization token if needed
//     const token = localStorage.getItem('auth_token');
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// Optional: Add response interceptor to handle global errors
// apiClient.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.error('API Error:', error.response || error.message);
//     return Promise.reject(error);
//   }
// );

export default apiClient;
