import React, { Component } from 'react';
import * as userActions from "../../../actions/index";
import { connect } from "react-redux";
import Loader from "../../../components/Utility/Loader.js";
import { Link } from "react-router-dom"
import Moment from 'react-moment';
import Logout from '../../../components/SuperAdmin/Logout.js';


class ViewUser extends Component {
    constructor(props) {
        super();
        this.state = {
            userData: [],
            products: [],
            total: 0,
            monthly: 0,
            weekly: 0,
            cardStyle: {
                width: "100%",
                height: "100%",
                'borderRadius': "20px"
            },
            isloading: true,
            divProfileImage: {
                height: "250px",
                width: "250px"
            },
            profileImage: {
                'borderRadius': '50%'
            },
            verifiedStyle: {
                'color': '#fff',
                'backgroundColor': '#0a2558',
                'borderRadius': '50%',
            },
            headerCard: {
                /* width: 100%; */
                padding: '0px 22px',
                margin: '0 20px',
                'borderRadius': '12px',
                'boxShadow': '0 5px 10px rgba(0, 0, 0, 0.1)'
            },
        };
    }
    componentDidMount() {
        // console.log(this.props.location)
        let search = this.props.location;
        const id = search.pathname.replace('/super-admin-view-user/', '');
        const form_data = new FormData();
        form_data.append("id", id)
        this.props.ViewUser(form_data).then((response) => {
            if (response.success === true) {
                // console.log(response?.data.orders)
                this.setState({
                    userData: response.data?.user,
                    products: response?.data?.user?.orders,
                    total: response?.data?.totalPurchase,
                    monthly: response?.data?.monthlyPurchase,
                    weekly: response?.data?.weeklyPurchase,
                })

                setTimeout(() => {
                    this.setState({
                        isloading: false
                    })
                }, 500);
            } else {
                console.log("error")
            }
        })

    }
    render() {
        const { userData, isloading, products, total, monthly, weekly } = this.state;
        // const products = [
        //     {
        //         image: "https://images.pexels.com/photos/12164656/pexels-photo-12164656.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'

        //     },
        //     {
        //         image: "https://images.pexels.com/photos/19090/pexels-photo.jpg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'
        //     },
        //     {
        //         image: "https://images.pexels.com/photos/10902287/pexels-photo-10902287.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'
        //     },
        //     {
        //         image: "https://images.pexels.com/photos/7429411/pexels-photo-7429411.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'
        //     },
        //     {
        //         image: "https://images.pexels.com/photos/11962361/pexels-photo-11962361.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'
        //     },
        //     {
        //         image: "https://images.pexels.com/photos/4252943/pexels-photo-4252943.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
        //         title: "Shoes for men",
        //         description: "Lorem ipsum dolor sit amet diam nonummy nibh dolore.",
        //         price: '120'
        //     },
        // ]
        return (
            <>
                <React.Fragment>
                    {isloading === true ? <Loader /> : ''}

                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">User</span>
                            </div>
                            <div>
                                <Logout />
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="row">
                                <div className="col-lg-12">

                                    <div className="card" style={this.state.headerCard}>
                                        <div className="card-header bg-white">
                                            <div className="d-flex align-items-center">
                                                <h5 className="card-title flex-grow-1 mb-0">User </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="sales-boxes">
                                <div className="recent-sales box">
                                    <div className="title "></div>
                                </div>
                            </div> */}
                                <div className="container my-2">
                                    <div className="row mx-3">
                                        <div className="col-md-6">
                                            <div className="card profile-sidebar" style={this.state.cardStyle}>


                                                <div className="profile-userpic m-auto" style={this.state.divProfileImage}>
                                                    <img className="card-img-top img-responsive" style={this.state.profileImage} alt="" src="/static/media/Default-welcomer.ca7543eb8c998f624dc7.png" height="100%" width="100%" />
                                                </div>


                                                <div className="profile-usertitle text-center">
                                                    <div className="profile-usertitle-name">
                                                        {userData.full_name}<i
                                                            style={this.state.verifiedStyle}
                                                            className="fa fa-check"></i>
                                                    </div>
                                                    {/* <div className="profile-usertitle-job">
                                                        Creator */}
                                                    {/* </div> */}
                                                </div>




                                                <div className="portlet light bordered">

                                                    <div>
                                                        <h4 className="profile-desc-title">About {userData.full_name}</h4>
                                                        <span className="profile-desc-text ms-3 mx-3"> Lorem ipsum dolor sit amet diam nonummy nibh dolore. </span>
                                                        <div className="margin-top-20 profile-desc-link">

                                                            <i className="bx bx-envelope"></i>
                                                            <a href={`mailto:${userData.email}`}>{userData.email}</a>
                                                        </div>
                                                        <div className="margin-top-20 profile-desc-link">
                                                            <i className="bx bx-phone-call"></i>
                                                            <a href={`tel:${userData.phone_no}`}>{userData.phone_no}</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-lg-6 col-md-6">
                                            <div className="card h-25" style={this.state.cardStyle}>
                                                <h4 className='mt-3'>Purchase</h4>
                                                <div className="row list-separated profile-stat my-3">
                                                    <div className="col-md-4 col-sm-4 col-xs-6 text-center">
                                                        <div className="uppercase profile-stat-title"> {total} </div>
                                                        <div className="uppercase profile-stat-text"> Total Purchase </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-xs-6 text-center">
                                                        <div className="uppercase profile-stat-title "> {monthly} </div>
                                                        <div className="uppercase profile-stat-text"> Monthy Purchase </div>
                                                    </div>
                                                    <div className="col-md-4 col-sm-4 col-xs-6 text-center">
                                                        <div className="uppercase profile-stat-title "> {weekly} </div>
                                                        <div className="uppercase profile-stat-text"> Weekly Purchase </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mt-2 ms-3">
                                                <h5 className='mt-2 mb-2'>Last 5 orders:</h5>
                                                <ul className='product-list '>
                                                    {products?.map((item, index) => {
                                                        console.log(item.order_items[0].productJson.item)
                                                        return (
                                                            <>
                                                                <li className='mb-2' key={index}>
                                                                    <Link to={`/super-admin-view-order/${btoa(item.id)}`} className='text-dark'>
                                                                        <div className='d-flex'>
                                                                            <div>
                                                                                <div className='purchased-product-image'>
                                                                                    <img src={item.order_items[0].productJson.item.pic_url} alt="" />
                                                                                </div>
                                                                                <div className='status-tags-orders'>
                                                                                    <p className={`vc text-content ${item.status === "success" ? 'in-progress' : ''}`}>{item.status === "success" ? 'In-progress' : 'Delivered'}</p>
                                                                                </div>

                                                                            </div>
                                                                            <div>
                                                                                <div className="ms-3 mt-2 product-title">
                                                                                    <div className='d-flex'>
                                                                                        <p className='w-75 flex-shrink-0 text-start'>
                                                                                            {item.order_items[0].productJson.item.title}
                                                                                        </p>
                                                                                        <span className='float-end flex-grow-1 text-center'>${item.order_items[0].productJson.item.price}</span>

                                                                                    </div>
                                                                                    <div className='d-flex mt-3'>
                                                                                        <p className='w-75 flex-shrink-0 text-start'>
                                                                                            Subtotal:
                                                                                        </p>
                                                                                        <span className='float-end flex-grow-1 text-center'>${item.totalprice}</span>

                                                                                    </div>
                                                                                    <div className='mt-3'>
                                                                                        <p className='text-start'>                                                    <Moment format="DD/MM/YYYY">{item.created_at}</Moment></p>
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>

                                                                    </Link>
                                                                </li>
                                                                <hr />
                                                            </>
                                                        )
                                                    })}

                                                </ul>

                                            </div>
                                            {/* <div className="card h-50 mt-2" style={this.state.cardStyle}>

                                        </div> */}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </section>
                </React.Fragment>
            </>
        )
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}


const mapDispatchToProps = {
    ViewUser: userActions.ViewUser,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ViewUser);