import React, { Component, useEffect } from 'react';
import LoginHeader from '../../components/LoginHeader';
import LoginFooter from '../../components/LoginFooter';
import axios from './../../axios'; // Import Axios
import { ToastContainer, toast } from 'react-toastify';
import { Navigate } from 'react-router-dom';
import * as userActions from '../../actions/index';
import { connect } from 'react-redux';
import * as actionType from '../..//constants/actionTypes';

import Loader from '../../components/Utility/Loader';

import { useAuth0 } from '@auth0/auth0-react';

export const Profile = ({ setEmail, setUser, setUserError }) => {
  const { user, isAuthenticated, isLoading, error } = useAuth0();
  useEffect(() => {
    if (error?.message) {
      console.log("############# PROFILE ERROR", error);
      setUserError(error?.message);
      return;
    }

    if (isAuthenticated && user && typeof setEmail === 'function') {
      setEmail(user.email);
    }

    setUser(user);
  }, [isAuthenticated, user, setEmail, setUser, error]);

  // if (isLoading) {
  //   return <div>Loading ...</div>;
  // }

  return (
    isAuthenticated && (
      <div>
        {/* <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p> */}
      </div>
    )
  );
};

class SigninVerifyComp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      code: '',
      secret: '',
      qrCodeUrl: '',
      password: '',
      email: '',
      user: null,
      userError: ''
    };
  }

  setEmail = (email) => {
    this.setState({ email }, () => {
      console.log('Email set in state:', this.state.email);
    });
  };

  setUser = (user) => {
    this.setState({ user }, () => {
      console.log('user set:', this.state.user);
    });
  };

  setUserError = (error) => {
    this.setState({ userError: error });
  };

  componentDidMount() {
    const storedSecret = localStorage.getItem('secret');
    const storedCode = localStorage.getItem('qrCodeUrl');

    if (storedSecret && storedCode) {
      this.setState({
        secret: storedSecret
      });
    }
  }

  verifyMFA = async () => {
    const { code, secret, email, password } = this.state;
    const token = localStorage.getItem('token');

    try {
      const requestData = { secret, code, email };
      function extractUsername(email) {
        return email && email.split('@')[0];
      }

      // Get the username
      const full_name = extractUsername(email);

      if (code !== '') {
        const response = await axios.post(`/verifyMFA`, requestData);
        console.log('response.data.success', response);
        if (response.data.verified === true) {
          console.log('response.data.success', response);

          // MFA verification succeeded
          toast.success('MFA verified successfully', {
            position: toast.POSITION.TOP_RIGHT
          });

          // Check if the user exists in the database
          const allUsersResponse = await axios.get(`/users`);
          console.log('allUsersResponse', allUsersResponse);
          let users;
          if (allUsersResponse.data && Array.isArray(allUsersResponse.data)) {
            users = allUsersResponse.data;
            // Proceed with user existence check
          } else {
            console.error(
              'Error fetching user data or unexpected response structure:',
              allUsersResponse.data
            );
            // Handle error case appropriately, e.g., show an error message to the user
          }

          let userExists = false;
          if (Array.isArray(users)) {
            // Check if users is an array
            users.forEach((user) => {
              if (user.email === email) {
                userExists = true;
                return; // exit the loop early since we found a match
              }
            });
          } else {
            console.error("Expected 'users' to be an array but got:", users);
          }

          console.log('userExists', userExists);
          if (userExists) {
            // User exists, proceed with sign-in
            const form_data = new FormData();
            form_data.append('user_type', 'customer');
            form_data.append('email', email); // Assuming email is required for sign-in
            form_data.append('password', password);
            // Call the sign-in API endpoint with the new URL
            const signInResponse = await this.props.onSignIn(form_data);
            console.log('signInResponse signin', signInResponse.data);
            if (signInResponse.success === true) {
              localStorage.setItem('token', signInResponse.data.token);

              // GetAuthUser with Authorization header containing token
              const authUserResponse = await this.props.onGetAuthUser();
              console.log(
                '(authUserResponse.data.success',
                authUserResponse.data.success
              );
              if (authUserResponse.data.success === true) {
                toast.success('Logged in successfully', {
                  position: toast.POSITION.TOP_RIGHT
                });
                window.location.href = '/';
              } else {
                toast.error(authUserResponse.data.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            } else {
              // Sign-in failed
              toast.error(signInResponse.data.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          } else {
            console.log('userExists else');

            // User does not exist, proceed with sign-up
            const signUpResponse = await this.props.onSignUp({
              email,
              full_name,
              password
            }); // Assuming you only need email for sign-up
            console.log('signUpResponse', signUpResponse);

            // Proceed with the sign-in process only if sign-up is successful
            if (signUpResponse.success === true) {
              // Sign-up successful, proceed with sign-in and redirection to "/"
              const form_data = new FormData();
              form_data.append('user_type', 'customer');
              form_data.append('email', email); // Assuming email is required for sign-in
              form_data.append('password', password);
              // Call the sign-in API endpoint with the new URL
              const signInResponse = await this.props.onSignIn(form_data);
              console.log('signInResponse signin', signInResponse.data);
              if (signInResponse.success === true) {
                localStorage.setItem('token', signInResponse.data.token);

                // GetAuthUser with Authorization header containing token
                const authUserResponse = await this.props.onGetAuthUser();
                console.log(
                  '(authUserResponse.data.success',
                  authUserResponse.data.success
                );
                if (authUserResponse.data.success === true) {
                  toast.success('Logged in successfully', {
                    position: toast.POSITION.TOP_RIGHT
                  });
                  window.location.href = '/';
                } else {
                  toast.error(authUserResponse.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
              } else {
                // Sign-in failed
                toast.error(signInResponse.data.message, {
                  position: toast.POSITION.TOP_RIGHT
                });
              }
            } else {
              // Signup failed
              toast.error(signUpResponse.data.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          }
        } else {
          // MFA verification failed
          toast.error(response.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } else {
        console.error('Please enter verification Code');
        toast.error('Please enter verification Code', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error(error);
      toast.error('MFA verification failed', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  verifyUser = async () => {
    const { email, password } = this.state;

    if (!email) {
      toast.error('Email not exist', { position: toast.POSITION.TOP_RIGHT });
      return;
    }

    const extractUsername = (email) => email && email.split('@')[0];
    const full_name = extractUsername(email);

    try {
      // Fetch all users
      const allUsersResponse = await axios.get(`/users`);
      const users = Array.isArray(allUsersResponse.data)
        ? allUsersResponse.data
        : [];

      if (!users.length) {
        console.error('No users found or invalid user data structure.');
      }

      // Check if user exists
      const userExists = users.some((user) => user.email === email);
      console.log('userExists:', userExists);

      if (userExists) {
        // User exists, proceed with sign-in
        const form_data = new FormData();
        form_data.append('user_type', 'customer');
        form_data.append('email', email);
        form_data.append('password', password);

        const signInResponse = await this.props.onSignIn(form_data);

        if (signInResponse.success) {
          localStorage.setItem('token', signInResponse.data.token);

          const authUserResponse = await this.props.onGetAuthUser();
          if (authUserResponse.data.success) {
            toast.success('Logged in successfully', {
              position: toast.POSITION.TOP_RIGHT
            });
            window.location.href = '/';
          } else {
            toast.error(authUserResponse.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        } else {
          toast.error(signInResponse.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      } else {
        // User does not exist, proceed with sign-up
        const signUpResponse = await this.props.onSignUp({
          email,
          full_name,
          password
        });

        if (signUpResponse.success) {
          // Sign-up successful, proceed with sign-in
          const form_data = new FormData();
          form_data.append('user_type', 'customer');
          form_data.append('email', email);
          form_data.append('password', password);

          const signInResponse = await this.props.onSignIn(form_data);

          if (signInResponse.success) {
            localStorage.setItem('token', signInResponse.data.token);

            const authUserResponse = await this.props.onGetAuthUser();
            if (authUserResponse.data.success) {
              toast.success('Logged in successfully', {
                position: toast.POSITION.TOP_RIGHT
              });
              window.location.href = '/';
            } else {
              toast.error(authUserResponse.data.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          } else {
            toast.error(signInResponse.data.message, {
              position: toast.POSITION.TOP_RIGHT
            });
          }
        } else {
          toast.error(signUpResponse.data.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    } catch (error) {
      console.error('Error in verifyUser:', error);
      toast.error('Verification failed. Please try again.', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  generateQRCode = async () => {
    const { email } = this.state;
    console.log('email scan', email);
    const requestData = { email };
    try {
      const response = await axios.post(
        `/generateQRCode/${email}`,
        requestData
      );
      console.log('response.data.secret', response.data.secret);
      console.log('response.data.qrCodeUrl', response.data.qrCodeUrl);
      localStorage.setItem('secret', response.data.secret);
      localStorage.setItem('qrCodeUrl', response.data.qrCodeUrl);

      this.setState({
        secret: response.data.secret,
        qrCodeUrl: response.data.qrCodeUrl
      });
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const { code, qrCodeUrl, password, user, userError } = this.state;

    if (userError) {
      return (
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: '100vh', backgroundColor: '#f9f9f9' }}
        >
          <h4 className="text-danger mb-3">Oops! Something went wrong.</h4>
          <p className="text-secondary mb-3">
            It seems like there was an issue with your login. {userError}
          </p>
        </div>
      );
    }

    if (user && !user.email_verified) {
      return (
        <div className="login-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="login-form">
                <div className="max-w-screen-xl mx-auto py-20 flex items-center justify-center main-divv">
                  <div className="col-md-6 flex justify-center">
                    <img
                      src="/assets/images/sign-in.png"
                      alt="Sign In"
                      className="max-w-full main-divv-img"
                    />
                  </div>
                  <div className="col-md-6 flex items-center justify-center h-screen">
                    <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:pl-10 main-divv2">
                      <p className="text-xl font-semibold text-gray-700 mb-4">
                        Please verify your email to access the website.
                      </p>
                      <p className="text-lg text-gray-600 mb-4">
                        A verification email has been sent to{' '}
                        <span className="font-semibold text-blue-600">
                          {user?.email}
                        </span>
                        . Please check your inbox and follow the instructions to
                        complete the verification process.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    return (
      <>
        <Profile
          setEmail={this.setEmail}
          setUser={this.setUser}
          setUserError={this.setUserError}
        />
        ;
        {!user ? (
          <Loader />
        ) : (
          <div className="login-sec">
            <div className="conatiner-fluid">
              <div className="row">
                <div className="login-form">
                  <div className="max-w-screen-xl mx-auto py-20 flex items-center justify-center main-divv">
                    <div className="col-md-6 left_img">
                      <div className="w-full lg:w-1/2 flex justify-center">
                        <img
                          src="/assets/images/sign-in.png"
                          alt=""
                          className="max-w-full main-divv-img"
                        />
                      </div>
                    </div>
                    <div className="col-md-6 right_form flex items-center justify-center h-screen">
                      <div className="w-full lg:w-1/2 mt-10 lg:mt-0 lg:pl-10 main-divv2">
                        <h3 className="text-20 text-[#4D5053] font-500 md:pt-20 pt-15 md:leading-25.5px leading-18px field-sec text-center">
                          MFA Setup
                        </h3>
                        {!qrCodeUrl ? (
                          <>
                            <button
                              className="bg-[#F57523] mt-10 cursor-pointer w-full border rounded-[8px] text-[#FFF] text-14 font-600 leading-22px py-12 px-18 hover:bg-[#fff] hover:text-[#F57523] duration-300 sign-ups"
                              onClick={this.generateQRCode}
                            >
                              Generate QR Code
                            </button>
                          </>
                        ) : (
                          <p className="mt-3">
                            Scan QR Code In google Authenticator App
                          </p>
                        )}
                        <br />
                        {qrCodeUrl && <img src={qrCodeUrl} alt="QR Code" />}
                        <br />
                        {qrCodeUrl ? (
                          <>
                            {/* <p className='flex mt-10 font-450' style={{ justifyContent: 'center', color: 'black' }}> OR </p> */}
                            <input
                              className="py-15 px-18 md:px-18 max-w-428px rounded-[8px] border-[#CFD8DC] border w-full text-[#4D5053] text-14 font-400 leading-16px outline-[#3C50E0] focus:outline-none placeholder-text-[#A7A8AA] mt-3"
                              type="text"
                              placeholder="Enter code from Google Authenticator"
                              value={code}
                              onChange={(e) =>
                                this.setState({ code: e.target.value })
                              }
                              style={{ width: '51%' }}
                            />
                            <button
                              className="bg-[#F57523] mt-10 cursor-pointer w-full border rounded-[8px] text-[#FFF] text-14 font-600 leading-22px py-12 px-18 hover:bg-[#fff] hover:text-[#F57523] duration-300  mt-3  sign-ups"
                              onClick={this.verifyMFA}
                            >
                              Verify MFA
                            </button>
                          </>
                        ) : (
                          <>
                            <p
                              className="flex mt-10 font-450"
                              style={{
                                justifyContent: 'center',
                                color: 'black'
                              }}
                            >
                              {' '}
                              OR{' '}
                            </p>

                            <button
                              className="bg-[#F57523] mt-10 cursor-pointer w-full border rounded-[8px] text-[#FFF] text-14 font-600 leading-22px py-12 px-18 hover:bg-[#fff] hover:text-[#F57523] duration-300  mt-3  sign-ups"
                              onClick={this.verifyUser}
                            >
                              Skip
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {/*  */}
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  onSignIn: userActions.SignIn,
  onGetAuthUser: userActions.GetAuthUser,
  onSignUp: userActions.SignUp,
  onGetAuthUser1: userActions.GetAuthUser1

  // onSocialSignIn: userActions.SocialSignIn,
  // onForgotPassword: userActions.ForgotPassword,
};

// Wrap the component with withAuth0 HOC
export default connect(mapStateToProps, mapDispatchToProps)(SigninVerifyComp);
