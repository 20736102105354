import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

/*** AddCart Action ***/
export const AddCartStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.AddCart_START,
    };
};

export const GetCartStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.GetCart_START,
    };
}

export const AddCartSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.AddCart_SUCCESS,
    };
};

export const GetCartSuccess = (response) => {
    return {
        token: false,
        loader: false,
        type: Master_actionType.GetCart_SUCCESS,
    };
};

export const AddCartFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.AddCart_FAIL,
    };
};

export const GetCartFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.GetCart_FAIL,
    };
};


export const QtyUpStart = () => {
    return {
        error: null,
        loader: true,
        type: Master_actionType.QtyUp_START,
    };
}

export const QtyUpSuccess = (response) => {
    return {
        token: false,
        loader: false,
        data: response,
        type: Master_actionType.QtyUp_SUCCESS,
    };
};


export const QtyUpFail = (error) => {
    return {
        error: error,
        loader: false,
        type: Master_actionType.QtyUp_FAIL,
    };
};


export const AddCart = (form_data) => {
    return (dispatch) => {
        dispatch(AddCartStart());

        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/AddCart`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(AddCartSuccess("User login successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(AddCartFail("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const GetCart = () => {
    return (dispatch) => {
        dispatch(GetCartStart());
        return axios({
            method: "get",
            url: `${actionType.environment_url}/GetCart`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(GetCartSuccess("Get Cart successfully !!!"));
                return response.data;
            })
            .catch(function (error) {
                dispatch(GetCartFail("Something went wrong, Please try again."));
                return error;
            });
    };
} 

export const CartQtyUpdate = (form_data) => {
    return (dispatch) => {
        dispatch(QtyUpStart());
        return axios({
            method: "post",
            data: form_data,
            url: `${actionType.environment_url}/QtyUpdate`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data.data , "QtyUpdate Response")
                return response.data.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again."));
                return error;
            });
    };
}

export const RemoveProduct = (cartId) => {
    return (dispatch) => {
        // dispatch(QtyUpStart());
        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/remove-product`,
            params: { cartId: cartId },
        })
            .then(function (response) {
                // dispatch(QtyUpSuccess(response.data.data));
                console.log(response.data , "Remove Product")
                return response.data;
            })
            .catch(function (error) {
                dispatch(QtyUpFail("Something went wrong, Please try again. RemoveProduct"));
                return error;
            });
    };
}