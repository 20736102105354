import React from 'react';
import { connect } from 'react-redux';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as userActions from '../../../actions/index';
import * as userActionsCart from '../../../actions/account';
import * as AccountActions from '../../../actions/account';
import Loader from '../../../components/Utility/Loader';
import LoginHeader from '../../../components/LoginHeader';
import LoginFooter from '../../../components/LoginFooter';
import getSymbolFromCurrency from 'currency-symbol-map';
import TranslateComponent from '../../Utility/TranslateComponent';


class ProductDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carousel_responsive: {
        superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
        desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3 },
        tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
        mobile: { breakpoint: { max: 464, min: 0 }, items: 3 }
      },
      quantity: 1,
      loader: false,
      valid: true,
      like: false,
      product_sku_requirements: props?.location?.state?.product_detail?.item_id,
      product_detail: {},
      active_properties: {},
      requirements_validation: {},
      Unauthorized: false,
      currencyRate: null,
      redirectCheckout: false,
      redirectToCart: false,
      cartStatus: 'Add to Cart'
    };
    this.AddToCart = this.AddToCart.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    this.setState({currencyRate: localStorage.getItem('currencyRate')});
    this.setState({ loader: true });
    const search = this.props.location.search.substring(1);

    this.props.ProductDetailById(search).then((response) => {
      console.log('response :::::::::::::::::::::::::', response.response.data);
      if (search) {
        const product_item = response.response.data;

        if (product_item) {
          const product_data = this.extractProductData(product_item);
          this.setState({ loader: false, product_detail: product_data });
        } else {
          this.setState({ loader: false });
          toast.error('Product Detail not found', {
            position: toast.POSITION.TOP_RIGHT
          });
          window.history.back();
        }
      } else {
        toast.error('Please add some text or link to search items', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    });
  }

  extractProductData(product_item) {
    const product_data = {
      title: product_item?.title,
      external_id: product_item?.item_id.toString(),
      external_url: product_item?.detail_url,
      product_primary_image: product_item?.pic_urls[0],
      service_charges: product_item?.charge || 0,
      orginal_price: product_item?.orginal_price || 0,
      price: product_item?.price || product_item?.orginal_price,
      quantity: this.state.quantity,
      skus: product_item?.skus,
      seller_info: product_item?.seller_info,
      product_images: product_item?.pic_urls || [],
      promotion_price: product_item?.promotion_price / 100
    };

    if (product_item?.props_list) {
      product_data.product_requirements =
        this.extractProductRequirements(product_item);
    }

    return product_data;
  }

  extractProductRequirements(product_item) {
    const product_requirements = {};
    for (const key in product_item.props_list) {
      const [propsListNewKey, propsListKeyValue] =
        product_item.props_list[key].split(':');
      if (!product_requirements[propsListNewKey]) {
        product_requirements[propsListNewKey] = [];
      }
      product_requirements[propsListNewKey].push({
        properties: key,
        value: propsListKeyValue.trim()
      });
    }
    return product_requirements;
  }

  DecreaseItem = () => {
    this.setState((prevState) => ({
      quantity: Math.max(prevState.quantity - 1, 1)
    }));
  };

  IncrementItem = () => {
    this.setState((prevState) => ({
      quantity: prevState.quantity + 1
    }));
  };

  AddToCart = (event, item) => {
    if (!this.state.valid || this.state.quantity === 0) {
      toast.error(
        this.state.valid
          ? 'Please enter a valid quantity'
          : 'Please Select Product Requirements Properly',
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
      return;
    }

    const form_data = new FormData();
    form_data.append('quantity', this.state.quantity);
    const product_detail = {
      ...this.state.product_detail,
      quantity: this.state.quantity
    };
    form_data.append('product_detail', JSON.stringify(product_detail));
    form_data.append(
      'product_requirements',
      this.state.product_sku_requirements
    );
    if (item === 'buy_now') {
      form_data.append('clear_cart', '1');
    }

    this.props
      .onAddCart(form_data)
      .then((response) => {
        if (response.success) {
          if (item === 'buy_now') {
            this.setState({ redirectCheckout: true });
          } else {
            this.setState({ cartStatus: 'Go to Cart' });
            this.props.onCart().then((cartResponse) => {
              this.setState({ cartItemCount: cartResponse.data?.length });
            });
          }
          toast.success(response.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        } else {
          this.handleCartError(response);
        }
      })
      .catch((error) => {
        console.error('Error adding to cart:', error);
        toast.error('An error occurred while adding the item to your cart', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  };

  handleCartError(response) {
    if (response.message === 'Unauthorized') {
      toast.error(
        'You are not logged in. Please log in to save items to your shopping cart',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      setTimeout(() => this.setState({ Unauthorized: true }), 6000);
    } else {
      toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
    }
  }

  AddToWishlist = (event) => {
    if (!this.state.valid || this.state.quantity === 0) {
      toast.error(
        this.state.valid
          ? 'Please enter a valid quantity'
          : 'Please Select Product Requirements Properly',
        {
          position: toast.POSITION.TOP_CENTER
        }
      );
      return;
    }

    const form_data = new FormData();
    form_data.append('quantity', this.state.quantity);
    const product_detail = {
      ...this.state.product_detail,
      quantity: this.state.quantity
    };
    form_data.append('product_detail', JSON.stringify(product_detail));
    form_data.append(
      'product_requirements',
      this.state.product_sku_requirements
    );

    this.props.onWishList(form_data).then((response) => {
      if (response.success) {
        toast.success(response.message, { position: toast.POSITION.TOP_RIGHT });
        this.setState({ like: true });
        this.props.onGetWishlist().then((wishlistResponse) => {
          this.setState({ whishListItemCount: wishlistResponse?.length });
        });
      } else {
        this.handleWishlistError(response);
      }
    });
  };

  handleWishlistError(response) {
    if (response.message === 'Unauthorized') {
      toast.error(
        'You still not login, please login to save item to your shopping cart',
        {
          position: toast.POSITION.TOP_RIGHT
        }
      );
      setTimeout(() => this.setState({ Unauthorized: true }), 6000);
    } else {
      toast.error('No results found with this text', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  requirementsHandler = (item, properties_name) => {
    const active_properties = { ...this.state.active_properties };
    if (item?.url) {
      const product_detail = {
        ...this.state.product_detail,
        product_primary_image: item.url
      };
      this.setState({ product_detail });
    }

    active_properties[properties_name] = item.properties;
    const allProperties = Object.values(active_properties);
    this.state.product_detail.skus.sku.forEach((sku) => {
      const properties = sku.properties.split(';');
      if (properties.every((prop) => allProperties.includes(prop))) {
        this.setState((prevState) => ({
          product_detail: {
            ...prevState.product_detail,
            orginal_price: sku.orginal_price,
            price: sku.price
          },
          product_sku_requirements: sku.properties_name
        }));
      }
    });

    const requirements_validation = {
      ...this.state.requirements_validation,
      [properties_name]: true
    };
    const valid = Object.keys(
      this.state.product_detail.product_requirements
    ).every((key) => key in requirements_validation);
    this.setState({ active_properties, requirements_validation, valid });
  };

  GetProduct = (product_search) => {
    if (product_search) {
      this.props.onGetproduct(product_search).then((response) => {
        if (response.success) {
          const product_item = response.data?.item;
          if (!product_item) {
            this.setState({
              is_redirect: true,
              redirect_url: `/product-list/search?${product_search}`
            });
            return;
          }
          const product_data = this.extractProductData(product_item);
          this.setState({ loader: false, product_detail: product_data });
        } else {
          this.setState({ loader: false });
          toast.error('No results found with this text', {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      });
    } else {
      toast.error('Please add some text or link to search items', {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  render() {
    const {
      redirectCheckout,
      Unauthorized,
      quantity,
      product_detail,
      loader,
      cartStatus,
      like,
      whishListItemCount,
      cartItemCount,
      currencyRate
    } = this.state;

    if (this.state.redirectToCart) {
      return <Navigate to="/cart" />;
    }
    if (redirectCheckout) {
      return <Navigate to="/checkout" />;
    }
    if (Unauthorized) {
      return <Navigate to="/login" />;
    }
    if (loader) {
      return <Loader />;
    }

    console.log(product_detail, "Product detail ##############")

    return (
      <React.Fragment>
        <div className="product-sec">
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                {product_detail?.product_primary_image && (
                  <div className="swiper mySwiper2">
                    <img
                      src={product_detail?.product_primary_image}
                      alt="product"
                      style={{ width: '100%' }}
                    />
                  </div>
                )}
                <div className="product-slider">
                  {product_detail?.product_images && (
                    <Carousel responsive={this.state.carousel_responsive}>
                      {product_detail.product_images.map((imgSrc, index) => (
                        <div
                          key={index}
                          onClick={() =>
                            this.setState({
                              product_detail: {
                                ...product_detail,
                                product_primary_image: imgSrc
                              }
                            })
                          }
                        >
                          <img src={imgSrc} alt="product" />
                        </div>
                      ))}
                    </Carousel>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="product-detail">
                  <h1>
                  {/* {product_detail?.title} */}
                  <TranslateComponent
                      content={product_detail?.title}
                      targetLanguage="en"
                    />
                  </h1>
                  <h6 className="mb-3">                    {getSymbolFromCurrency('NGN')}
                    {parseFloat(product_detail?.promotion_price * currencyRate).toFixed(2)}
                  </h6>
                  <h5 className="mb-3">
                    Service charges: ¥ {product_detail?.service_charges}
                  </h5>
                  <div className="quantity-btn btn-added">
                    <p>Qty</p>
                    <input
                      type="button"
                      className="minus"
                      value="-"
                      onClick={this.DecreaseItem}
                    />
                    <input
                      readOnly
                      type="number"
                      className="input-text qty text"
                      value={quantity}
                    />
                    <input
                      type="button"
                      className="plus"
                      value="+"
                      onClick={this.IncrementItem}
                    />
                  </div>
                  {product_detail?.product_requirements &&
                    Object.keys(product_detail.product_requirements).map(
                      (key) => (
                        <div
                          key={key}
                          className={
                            this.state.requirements_validation[key]
                              ? 'panel panel-default'
                              : 'panel panel-danger'
                          }
                        >
                          <div className="panel-heading">
                            <h3 className="panel-title">{key}</h3>
                          </div>
                          <div className="panel-body">
                            {product_detail.product_requirements[key].map(
                              (item) => (
                                <button
                                  key={item.properties}
                                  onClick={() =>
                                    this.requirementsHandler(item, key)
                                  }
                                >
                                  {item.value}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      )
                    )}
                </div>
                <div className="product-btn">
                  <button
                    className="btn btn-primary buy-now"
                    onClick={(event) => this.AddToCart(event, 'buy_now')}
                  >
                    <i className="fa-solid fa-bag-shopping"></i> Buy Now
                  </button>
                  <button
                    className="btn btn-primary cart"
                    onClick={(event) => this.AddToCart(event, 'add_to_cart')}
                  >
                    <img src="/assets/images/cart-img.png" alt="cart" />{' '}
                    {cartStatus}
                  </button>
                  <button
                    className="btn btn-primary wish"
                    onClick={this.AddToWishlist}
                  >
                    <i
                      className={like ? 'fa fa-heart like' : 'fa-heart dislike'}
                      aria-hidden="true"
                    ></i>{' '}
                    Add To Wishlist
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error,
    products: state.Product
  };
}

const mapDispatchToProps = {
  onCart: userActionsCart.GetCart,
  onGetproduct: userActions.Getproduct,
  onAddCart: userActions.AddCart,
  onWishList: AccountActions.AddWishlist,
  onGetWishlist: userActions.GetWishlist,
  ProductDetailById: userActions.ProductDetailById
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
