import axios from "axios";
import * as actionType from "../constants/actionTypes";
import * as Master_actionType from "../constants/MasteractionTypes";

let qs = require("qs");

/*** Getproduct Action ***/
export const Getproduct_START = () => {
    return {
        error: null,
        loader: false,
        isAuthenticated: true,
        type: Master_actionType.Getproduct_START,
    };
};

export const Getproduct_SUCCESS = (response) => {
    return {
        error: false,
        loader: false,
        productData: response,
        isAuthenticated: response?.status === 401 ? false : true,
        type: Master_actionType.Getproduct_SUCCESS,
    };
};
export const Getproduct_FAIL = (error) => {
    return {
        error: error,
        loader: false,
        isAuthenticated: error.status === 401 ? false : true,
        type: Master_actionType.Getproduct_FAIL,
    };
};
export const Getproduct = (search, pageNo, website) => {
    return (dispatch) => {
        dispatch(Getproduct_START());

        let search_item;
        if (search?.type) {
            search_item = { keyword: search?.search_input, categories: search?.search_input, website: website }
            if (search.type == "categories") {
                search_item = { keyword: search?.search_input, categories: search?.search_input, website: website }
            } else {
                search_item = { categoryId: search.categoryId, categoriesProducts: search.categoriesProducts, website: website }
            }
        } else {
            search_item = { keyword: search, page_no: pageNo ? pageNo : 1, website: website }
        }

        console.log('search_item', search_item)

        return axios({
            method: "get",
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/item_search`,
            params: search_item, pageNo,
            paramsSerializer: (params) => {
                return qs.stringify(params);
            },
        })
            .then(function (response) {
                console.log('response search', response)
                dispatch(Getproduct_SUCCESS(response?.data?.response?.data?.data));
                return response;
            })
            .catch(function (error) {
                dispatch(Getproduct_FAIL("Something went wrong, Please try again."));
                return error;
            });
    };
};

export const ProductDetailById = (external_Id) => {
    return async () => {
        try {
            const response = await axios({
                method: 'get',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('shiporfly_authtoken')}`,
                },
                url: `${actionType.environment_url}/product_details`,
                params: { item_id: external_Id },
            });
            return response.data;
        } catch (error) {
            console.error('Error in ProductDetailById:', error.message);
            return { success: false, error: error.message };
        }
    };
};


export const getCategoriesList = () => {
    return (dispatch) => {
        return axios({
            method: "get",
            headers: {
                'Content-Type': "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
            url: `${actionType.environment_url}/get-product-categories`
        }).then(function (response) {
            // dispatch(Getproduct_SUCCESS(response.data));
            return response.data;
        })
            .catch(function (error) {
                // dispatch(Getproduct_FAIL("Something went wrong, Please try again."));
                return error;
            });
    }

}
