import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as userActions from "../../../actions/index";
import LoginHeader from "../../../components/LoginHeader";
import LoginFooter from "../../../components/LoginFooter";
import Loader from "../../../components/Utility/Loader";
import ReactPaginate from 'react-paginate';
import getSymbolFromCurrency from 'currency-symbol-map';

class ProductList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            zero_product: false,
            currentPage: 1,
            product_categories: this.props.location.state?.product_categories,
            header_search_loader: false,
            ApiCurrency: getSymbolFromCurrency('RMB'),
            ConversionCurrency: getSymbolFromCurrency('NGN'),
            currencyRate: null,
            search_loader: false
        };
    }
    GetProduct = (product_search) => {
        if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
            this.LoginHeaderEvent.LoginHeaderSearchLoaderStart();
        }

        if(product_search){
            this.props.onGetproduct(product_search).then((response) => {
                console.log(response, "++++++++++++++++response product list++++++++++++++++++++++++++++")
                if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                    this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
                }
                if (response.success === true) {
                    if (response.data?.item) {
                        this.setState({
                            is_redirect: true,
                            redirect_url: `/product-detail/search?${product_search}`,
                            redirect_state: {
                                search_input: product_search,
                                product_item: response.data?.item,
                            },
                        })
                    }else{
                        window.history.pushState(null, null, `/product-list/search?${product_search.trim()}`);
                        let product_categories = [];
                        if (response.data.length > 0 || response.data?.item) {
                            product_categories = response.data;
                            this.setState({
                                zero_product: false,
                                product_categories: product_categories,
                                currencyRate: response.currencyRate
                           });
                        }else{
                            this.setState({ zero_product: true });
                        }

                    }

                } else {
                    toast.error("No results found with this text", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                }
            });
        }else{
            if (this.LoginHeaderEvent && this.LoginHeaderEvent !== undefined) {
                this.LoginHeaderEvent.LoginHeaderSearchLoaderStop();
            }
            toast.error("Please add some text or link to search items", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }

    };

    handlePageChange = (event) => {
        const newOffset = (event.selected * 15) % this.state.product_categories.length;
        this.setState({ currentPage: newOffset });
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    CategoryHandler = (Id) => {
        this.setState({ search_loader: true });
        const search_input = {
            categoryId: Id,
            categoriesProducts: true,
            type: "categoriesProduct"
        };
        if (Id) {
            this.props
                .onGetproduct(search_input)
                .then((response) => {
                    this.setState({ search_loader: false });
                    if (response.success === true) {
                        console.log(response.data, "response.data ")
                        if (response.data.length > 0) {
                            this.setState({
                                is_redirect: true,
                                redirect_url: `/product-list/search?${search_input.categoryId}`,
                                redirect_state: {
                                    search_input: search_input,
                                    product_details: response.data,
                                    currencyRate: response.currencyRate,
                                },
                            });
                        } else {
                            toast.error("No Result Found", {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                        }
                    } else {
                        toast.error(response?.message, {
                            position: toast.POSITION.TOP_RIGHT,
                        });
                    }
                })
                .catch((error) => {
                    console.error("Error occurred during search:", error);
                    this.setState({ search_loader: false });
                    toast.error("An error occurred during search", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                });
        } else {
            this.setState({ search_loader: false });
            toast.error("Please add some text or link to search items", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    render() {
        const { product_categories, currentPage, zero_product } = this.state;
        const itemsPerPage = 15;
        const endIndex = currentPage + itemsPerPage;
        const products = product_categories?.filter(product => product.MainPictureUrl);
        console.log({products})
        // const products = product_categories?.filter(product => product.IconImageUrl);
        const currentPageData = products?.slice(currentPage, endIndex);
        const totalPages = Math.ceil(products?.length / itemsPerPage);
        console.log("++++++currentPageData+++++++++++==++=+====dwpk__", currentPageData);
        return (
            <React.Fragment>
                {this.state.is_redirect ? <Navigate to={this.state.redirect_url} state={this.state.redirect_state} /> : null}
                {this.state.search_loader ? <Loader /> : null}
                {this.props.loader ? <Loader /> : null}
                <div className="">
                
                    <div className="category-sec">
                        <div className="container-fluid">
                            <div className="row" id="currentPageData">
                                {zero_product && "NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS"}
                                {currentPageData.map((item, product_key) => 
                                    
                                    (
                                        <div className="col-lg-4 col-md-6" key={product_key}>
                                            <div className="product-box">
                                                <div id="div1">
                                                    <section className="section-grid">
                                                        <div className="grid-prod">
                                                            <div className="prod-grid">
                                                                <div onClick={() => this.CategoryHandler(item?.Id)} style={{ cursor: 'pointer' }}>
                                                                    <img src={item?.MainPictureUrl} alt="lower" width="400" height="400" />
                                                                    <h4>{item?.Title}</h4>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </section>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                
                                )}
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={this.handlePageChange}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </div>
                    </div>

                    
                </div>
            </React.Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}
const mapDispatchToProps = {
    onGetproduct: userActions.Getproduct,
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
