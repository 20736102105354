export const GET_COMPLETE_ORDERS_START = "GET_COMPLETE_ORDERS";
export const GET_COMPLETE_ORDERS_SUCCESS = "SignIn_SUCCESS";
export const GET_COMPLETE_ORDERS_FAIL = "GET_COMPLETE_ORDERS_FAIL";

export const GET_INPROGRESS_ORDERS_START = "GET_INPROGRESS_ORDERS_START";
export const GET_INPROGRESS_ORDERS_SUCCESS = "GET_INPROGRESS_ORDERS_SUCCESS";
export const GET_INPROGRESS_ORDERS_FAIL = "GET_INPROGRESS_ORDERS_FAIL";

export const siteurl = process.env.REACT_APP_API_URL;
export const environment_url = process.env.REACT_APP_API_URL;


