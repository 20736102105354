import React from "react";
import { connect } from "react-redux";
import "../../../assets/css/superAdmin.css";
import { CSmartTable, CAvatar, CBadge, CButton, CCollapse, CCardBody } from '@coreui/react-pro' // Assuming you've installed the CoreUI package
import "../../../assets/css/superAdmin.css";
import defaultImage from "../../../assets/static images/Default-welcomer.png";

import * as userActions from "../../../actions/getUsers";
import { Link } from "react-router-dom";

import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import Loader from "../../../components/Utility/Loader.js";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Logout from "../../../components/SuperAdmin/Logout.js";
class ManageCustomer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            details: [], // Add other state variables if 
            usersData: [],
            loader: false,
        };
    }
    componentWillMount() {

        this.props.GetUser().then((response) => {
            if (response.success === true) {
                this.setState({
                    usersData: response.response
                })
                console.log(response.response)
            } else {
                console.log("error")
            }
        });
    }



    toggleBlock = (event, id) => {
        event.preventDefault();
        const form_data = new FormData();
        form_data.append('id', id)
        confirmAlert({
            title: "Are you sure?",
            message: `You want to block this user`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        this.setState({
                            loader: true
                        })

                        this.props.BlockUser(form_data).then((response) => {
                            let data = response;
                            if (data.success === true) {

                                this.props.GetUser().then((response) => {
                                    if (response.success === true) {
                                        this.setState({
                                            usersData: response.response
                                        })
                                        setTimeout(() => {
                                            this.setState({
                                                loader: false
                                            })
                                            toast.success(data.message, {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        }, 2000);
                                    } else {

                                        setTimeout(() => {
                                            this.setState({
                                                loader: false
                                            })
                                            toast.error("Something went wrong!", {
                                                position: toast.POSITION.TOP_RIGHT,
                                            });
                                        }, 2000);
                                        console.log("error")
                                    }
                                });
                            } else {
                                setTimeout(() => {
                                    this.setState({
                                        loader: false
                                    })
                                    toast.error("Something went wrong!", {
                                        position: toast.POSITION.TOP_RIGHT,
                                    });
                                }, 2000);

                                console.log("error")
                            }
                        })
                    }
                },
                {
                    label: 'No',
                }
            ]
        });
    }
    toggleDelete = (event, id) => {
        event.preventDefault();
        const form_data = new FormData();
        form_data.append('id', id)
        confirmAlert({
            title: "Are you sure?",
            message: `You won't be able to revert this!`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.props.DeleteUser(form_data).then((response) => {
                        let data = response.data;
                        if (data.success === true) {
                            toast.success(data.message, {
                                position: toast.POSITION.TOP_RIGHT,
                            });
                            setTimeout(() => {
                                window.location.reload()
                            }, 2000);
                            event.target.closest("tr").remove();
                        } else {
                            console.log("error")
                        }
                    })
                },
                {
                    label: 'No',
                }
            ]
        });
    }
    handleLoad() {
        this.setState.loader = true
    }
    render() {
        var details = [];
        const columns = [
            {
                label: '#',
                key: 'index',
                filter: false,
                sorter: false,
            },
            {
                key: 'avatar',
                label: '',
                filter: false,
                sorter: false,
            },
            {
                key: 'full_name',
            },
            {
                key: 'email',
            },
            {
                key: "registered",
            },
            {
                key: 'role',
            },
            {
                key: 'status',
            },
            {
                key: 'show_details',
                label: 'Action',
                filter: false,
                sorter: false,
            },
            {
                key: 'Action',
                label: '',
                filter: false,
                sorter: false,
            },
        ]
        const { usersData } = this.state;

        const getBadge = (status) => {
            switch (status) {
                case 'Active':
                    return 'success'
                case 'Inactive':
                    return 'secondary'
                case 'Pending':
                    return 'warning'
                case 'Banned':
                    return 'danger'
                default:
                    return 'primary'
            }
        }


        const style = {
            width: '45%'
        }
        return (
            <>
                <React.Fragment>
                    <ToastContainer />
                    {this.state.loader ? <Loader /> : null}
                    {this.props.loader ? <Loader /> : null}

                    <section className="home-section">
                        <nav>
                            <div className="sidebar-button">
                                <i className='bx bx-menu sidebarBtn'></i>
                                <span className="dashboard">Users</span>
                            </div>
                            <div>
                                <Logout />
                            </div>
                        </nav>

                        <div className="home-content">
                            <div className="sales-boxes">
                                <div className="recent-sales box">
                                    <div className="row">
                                        <div className="col-lg-9 col-md-9">
                                            <div className="title ">Users</div>
                                        </div>
                                        <div className="col-lg-3 col-md-9 text-end">
                                            {/* <Link to="/super-admin-add-user" data-toggle="modal" data-target="#exampleModalCenter" style={style} className="btn btn-primary col-lg-3">Add user</Link> */}
                                        </div>
                                    </div>

                                    <CSmartTable
                                        activePage={2}
                                        cleaner
                                        clickableRows
                                        columns={columns}
                                        columnFilter
                                        columnSorter
                                        footer
                                        items={usersData}
                                        itemsPerPageSelect
                                        itemsPerPage={5}
                                        pagination
                                        onFilteredItemsChange={(items) => {
                                            console.log(items)
                                        }}
                                        onSelectedItemsChange={(items) => {
                                            console.log(items)
                                        }}
                                        scopedColumns={{
                                            index: (item, index) => {
                                                return (
                                                    <td>{index + 1}</td>
                                                )
                                            },
                                            avatar: (item) => (
                                                <td>
                                                    <CAvatar src={item.avatar ? `/images/avatars/${item.avatar}` : defaultImage} />
                                                </td>
                                            ),
                                            status: (item) => {
                                                return (
                                                    <td>
                                                        <div className="d-flex">
                                                            <CBadge className="mx-3" color={getBadge(item.verified === false ? "InActive" : "Active")}>{item.verified === false ? "Unverified" : "Verified"}</CBadge>
                                                            <CBadge color={getBadge(item.isBlocked === false ? "Active" : "Banned")}>{item.isBlocked === false ? "Unblocked" : "Blocked"}</CBadge>
                                                        </div>
                                                    </td>
                                                )
                                            },
                                            show_details: (item) => {
                                                return (
                                                    <td className="py-2">
                                                        <div className="d-flex">
                                                            {/* <Link
                                                                to={`/super-admin-edit-user/${btoa(item.id)}`}
                                                                className="btn btn-outline-primary btn-sm  me-3 rounded-circle mt-2"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="circle"
                                                                size="sm"
                                                                title="edit"
                                                            >
                                                                Edit
                                                                <i className="fa fa-solid fa-pencil pt-1"></i>

                                                            </Link> */}
                                                            <Link
                                                                to={`/super-admin-view-user/${btoa(item.id)}`}
                                                                className="btn btn-outline-primary btn-sm  rounded-circle mt-2"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="circle"
                                                                size="sm"
                                                                title="view"
                                                            >
                                                                {/* View */}
                                                                <i className="fa fa-solid fa-eye pt-1"></i>

                                                            </Link>
                                                            <CButton
                                                                className="btn btn-outline-primary btn-sm  rounded-circle ms-3 mt-2"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="circle"
                                                                size="sm"
                                                                title={`${item.isBlocked === true ? 'unblock' : 'block'}`}
                                                                onClick={(event) => this.toggleBlock(event, item.id)}
                                                            >
                                                                {/* View */}
                                                                <i className="fa fa-solid fa-ban pt-1"></i>

                                                            </CButton>
                                                            <CButton
                                                                className="btn btn-outline-primary btn-sm rounded-circle ms-3 mt-2"
                                                                color="primary"
                                                                variant="outline"
                                                                shape="circle"
                                                                size="sm"
                                                                title="delete"
                                                                onClick={(event) => this.toggleDelete(event, item.id)}>
                                                                {/* Delete */}
                                                                <i className="fa fa-trash fa-solid pt-1"></i>

                                                            </CButton>
                                                        </div>

                                                    </td>
                                                )
                                            },
                                            details: (item) => {
                                                return (
                                                    <CCollapse visible={details.includes(item.id)}>

                                                        <CCardBody className="p-3">
                                                            <h4>{item.name}</h4>
                                                            <p className="text-muted">User since: {item.registered}</p>
                                                            <CButton size="sm" color="info">
                                                                User Settings
                                                            </CButton>
                                                            <CButton size="sm" color="danger" className="ml-1">
                                                                Delete
                                                            </CButton>
                                                        </CCardBody>
                                                    </CCollapse>
                                                )
                                            },
                                        }}
                                        // selectable
                                        sorterValue={{ column: 'status', state: 'asc' }}
                                        tableFilter
                                        tableProps={{
                                            className: 'add-this-class',
                                            responsive: true,
                                            striped: true,
                                            hover: true,
                                        }}
                                        tableBodyProps={{
                                            className: 'align-middle'
                                        }}
                                    />

                                </div>

                            </div>
                        </div>
                    </section>

                </React.Fragment >
            </>
        );
    }
}
function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}


const mapDispatchToProps = {
    GetUser: userActions.GetUsers,
    DeleteUser: userActions.DeleteUser,
    BlockUser: userActions.BlockUser,
};

export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(ManageCustomer);