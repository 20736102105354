import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import LoginHeader from '../../components/LoginHeader';
import LoginFooter from '../../components/LoginFooter';
import * as productActions from '../../actions/product';
import * as userActions from '../../actions/account';
import Loader from '../../components/Utility/Loader';
import getSymbolFromCurrency from 'currency-symbol-map';
import axios from './../../axios'; // Ensure axios is imported
import TranslateComponent from '../../components/Utility/TranslateComponent';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      carouselResponsive: {
        superLargeDesktop: {
          items: 5,
          slidesToSlide: 3,
          breakpoint: { max: 4000, min: 3000 }
        },
        desktop: {
          items: 4,
          slidesToSlide: 4,
          breakpoint: { max: 3000, min: 1024 }
        },
        tablet: {
          items: 2,
          slidesToSlide: 2,
          breakpoint: { max: 1024, min: 464 }
        },
        mobile: {
          items: 1,
          slidesToSlide: 1,
          breakpoint: { max: 464, min: 0 }
        }
      },
      search_loader: false,
      website: null,
      sliderDiv: {
        height: '200px',
        width: '200px'
      },
      siderDivImg: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
      },
      zero_product: false,
      currentPage: 1,
      product_details: [],
      header_search_loader: false,
      ApiCurrency: getSymbolFromCurrency('RMB'),
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      currencyRate: null,
      searchKeyword: null,
      searchInput: '',
      pages: [1]
    };
  }

  async componentDidMount() {
    this.setState({ website: 'http://www.taobao.com/' });
    this.setState({currencyRate: localStorage.getItem('currencyRate')});
    this.props.getCategoriesList().then((response) => {
      if (response?.data?.length > 0) {
        response.data.shift();
        let data = response.data.filter((item) => item.IconImageUrl);
        this.setState({
          categories: data
        });
      }
    });

    this.props
      .onGetProduct('trending')
      .then((response) => {
        const productData = response?.data?.response?.data?.data;

        console.log('###response', productData);

        if (productData && productData.length > 0) {
          this.setState({ product_details: productData });
        } else {
          this.setState({ zero_product: true });
        }
      })
      .catch((error) => {
        // Handle any errors during the API call
        console.error('Error fetching product data:', error);
        this.setState({ loader: false });
        toast.error('An error occurred while fetching product data', {
          position: toast.POSITION.TOP_RIGHT
        });
      });
  }

  SearchHandler = async (value) => {
    if (value == '') {
      toast.error('Keyword is requied to search', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    const keyword = value || 'iphone';

    this.setState({ search_loader: true });

    try {
      const response = await axios.get(`/item_search?keyword=${keyword}`);

      if (response.data) {
        this.setState({
          is_redirect: true,
          redirect_url: `/product-list/search?${keyword}`,
          redirect_state: { results: response?.data?.response?.data?.data }
        });
      } else {
        toast.error('No Result Found', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      toast.error('An error occurred during search', {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      this.setState({ search_loader: false });
    }
  };

  handlerDropDownChange = (value) => {
    this.setState({ website: value });
  };

  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  // handleTranslationComplete = (translation) => {
  //   setTranslatedText(translation);
  // };

  render() {
    const {
      product_details,
      zero_product,
      ApiCurrency,
      currencyRate,
      ConversionCurrency,
      searchKeyword,
      searchInput
    } = this.state;

    const currentPageData = product_details;

    console.log("### CUrrency rate", currencyRate)

    return (
      <React.Fragment>
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}
        {this.state.search_loader ? <Loader /> : null}
        <div className="">
          <div className="category-slider">
            <div className="container-fluid">
              <div className="row">
                <div className="heading">
                  <h1>Search</h1>
                </div>
              </div>
              {/* Search Bar */}
              <div className="row">
                <div className="col-12">
                  <div className="col-12 position-relative">
                    <input
                      type="text"
                      value={searchInput}
                      onChange={this.handleSearchInputChange}
                      placeholder="Search for categories..."
                      className="form-control"
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          this.SearchHandler(searchInput);
                        }
                      }}
                    />
                    <i
                      className="fa fa-search position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                      style={{ cursor: 'pointer', padding: '20px' }}
                      onClick={() => this.SearchHandler(searchInput)}
                    ></i>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="category-sec">
                  <div className="container-fluid">
                    <div className="row" id="currentPageData">
                      {zero_product &&
                        'NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS'}
                      {currentPageData.map((item, product_key) => (
                        <div className="col-lg-4 col-md-6" key={product_key}>
                          <div className="product-card">
                            <Link
                              to={`/product-detail/search?${item?.item_id}`}
                              state={{
                                product_detail: item,
                                searchKeyword
                              }}
                              className="product-card__link"
                            >
                              {/* Product Image */}
                              <div className="product-card__image-container">
                                <img
                                  src={item?.main_image_url}
                                  alt={item?.title || 'Product Image'}
                                  className="product-card__image"
                                />
                              </div>

                              {/* Product Details */}
                              <div className="product-card__content">
                                {/* Product Title */}
                                <h2 className="product-card__title">
                                  <TranslateComponent
                                    content={item?.title}
                                    targetLanguage="en"
                                  />
                                </h2>

                                {/* Product Price */}
                                <div className="product-card__price">
                                  {/* <span className="product-card__currency">
                                    {ApiCurrency}
                                  </span> */}
                                  {/* <span className="product-card__amount">
                                    {parseFloat(item?.price).toFixed(2)}
                                  </span> */}
                                  <span className="#product-card__conversion card__amount">
                                    {/* ≈*/} {ConversionCurrency}
                                    {parseFloat(
                                      item?.price * currencyRate
                                    ).toFixed(2)}
                                  </span>
                                </div>
                              </div>
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ToastContainer />
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    productData: state.Product.productData
  };
}
const mapDispatchToProps = {
  onGetProduct: productActions.Getproduct,
  onCart: userActions.GetCart,
  getProduct: productActions.Getproduct,
  getCategoriesList: productActions.getCategoriesList
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(Home);
