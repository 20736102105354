import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import * as productActions from '../actions/product';
import * as userActions from '../actions/account';
import { useAuth0 } from '@auth0/auth0-react';

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const onLogout = () => {
    localStorage.removeItem('shiporfly_authtoken');
    logout();
  };

  return (
    <span className="sign-in" onClick={onLogout} style={{ cursor: 'pointer' }}>
      Sign Out
    </span>
  );
};

class LoginHeader extends React.Component {
  constructor(props) {
    console.log('props', props);
    super(props);
    this.state = {
      search_input: '',
      search_loader: false,
      isActive: false,
      showHeader: false,
      showHeaderDashboard: false,
      cartItemCount: null,
      whishListItemCount: null,
      user_info: null,
      website: null,
      buttonDIsable: false
    };
  }

  async componentDidMount() {
    try {
      this.setState({ website: 'http://www.taobao.com/' });
      const cartResponse = await this.props.onCart();
      const wishlistResponse = await this.props.onWishlist();

      this.setState({
        cartItemCount: cartResponse.data?.length,
        whishListItemCount: wishlistResponse?.length,
        user_info: cartResponse.user_info || wishlistResponse?.user_info,
        loading: false
      });

      if (this.props?.location?.state?.search_input) {
        this.setState({
          search_input: this.props?.location?.state?.search_input
        });
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  handleDropdownChange = async (event) => {
    this.setState({ website: event.target.value });
    this.props.handleDropdownChange(event.target.value);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps?.whishListItemCount) {
      this.setState({
        whishListItemCount: nextProps.whishListItemCount
      });
    }
    if (nextProps?.whishListItemCount === 0) {
      this.setState({
        whishListItemCount: null
      });
    }
    if (nextProps?.cartItemCount) {
      this.setState({
        cartItemCount: nextProps.cartItemCount
      });
    }
    if (nextProps.cartItemCount === 0) {
      this.setState({
        cartItemCount: ''
      });
    }
  }

  LoginHeaderSearchLoaderStart() {
    this.setState({ search_loader: true });
  }

  LoginHeaderSearchLoaderStop() {
    this.setState({ search_loader: false });
  }

  SearchSubmitHandler = (event) => {
    event.preventDefault();

    const { search_input } = this.state;
    if (
      this.props?.page_type &&
      (this.props?.page_type === 'product_list' ||
        this.props?.page_type === 'product_details')
    ) {
      this.props.propsGetProduct(search_input);
    } else {
      this.setState({ search_loader: true });
      if (search_input) {
        this.props
          .getProduct(search_input, this.state.website)
          .then((response) => {
            this.setState({ search_loader: false });
            if (response.success === true) {
              if (response.data?.item) {
                this.setState({
                  is_redirect: true,
                  redirect_url: `/product-detail/search?${search_input}`,
                  redirect_state: {
                    search_input: search_input,
                    product_item: response.data?.item
                  }
                });
              } else {
                if (response.data.length > 0) {
                  this.setState({
                    is_redirect: true,
                    redirect_url: `/product-list/search?${search_input}`,
                    redirect_state: {
                      search_input: search_input,
                      product_details: response.data,
                      currencyRate: response.currencyRate
                    }
                  });
                } else {
                  toast.error('No Result Found', {
                    position: toast.POSITION.TOP_RIGHT
                  });
                }
              }
            } else {
              toast.error(response?.message, {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          });
      } else {
        this.setState({ search_loader: false });
        toast.error('Please add some text or link to search items', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  };

  setShowHeader = (value) => {
    this.setState({
      showHeader: value
    });
  };
  setActive = (value) => {
    this.setState({
      isActive: value
    });
  };

  setShowDashboard = (value) => {
    if (value) {
      document.body.classList.add('dashboard-open');
    } else {
      document.body.classList.remove('dashboard-open');
    }
    this.setState({
      showHeaderDashboard: value
    });
  };

  render() {
    const {
      search_input,
      search_loader,
      isActive,
      showHeader,
      cartItemCount,
      whishListItemCount,
      user_info,
      showHeaderDashboard,
      props
    } = this.state;
    const email = user_info?.email;
    const username = email?.split('@')[0];

    if (!user_info) {
      return <div>Loading...</div>;
    }

    return (
      <React.Fragment>
        <ToastContainer />
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}

        <div
          className={
            isActive ? 'mobile-view show-search-section' : 'mobile-view'
          }
        ></div>
        <header className="header-main desktop-header">
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="site-logo">
                  <a href="/">
                    <img
                      src="/assets/images/site-logo.png"
                      className="logo-header"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col">
                <div className="button-sec">
                  <div className="btnns">
                    <Link to="/cart" className="cart">
                      <img src="/assets/images/cart.png" alt="cart" />
                      {cartItemCount > 0 ? (
                        <span className="cart-item-count">{cartItemCount}</span>
                      ) : (
                        ''
                      )}
                      Cart
                    </Link>
                    <Link to="/wishlist" className="whish-list">
                      <img src="/assets/images/wishlist.png" alt="cart" />
                      {whishListItemCount > 0 ? (
                        <span className="cart-item-count">
                          {whishListItemCount}
                        </span>
                      ) : (
                        ''
                      )}
                      Wish
                    </Link>
                    <div className="buy-btn">
                  <Link to="/how-to-buy" className="btn how-to-buy">
                    How To Buy
                  </Link>
                </div>
                  </div>
                </div>
              </div>

              <div className="col">
                {this.state.loading ? (
                  <div>Loading...</div>
                ) : (
                  <div>
                    {this.state.user_info ? (
                      <a
                        onClick={() => this.setShowDashboard(true)}
                        className="profile"
                      >
                        <img src="/assets/images/profile.png" alt="Sign-in" />
                        <span>Hi, {username}</span>
                      </a>
                    ) : (
                      <Link to="/login" className="sign-in">
                        <img src="/assets/images/login.png" alt="Sign-in" />
                        Sign In
                      </Link>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <header className="header-main mobile_header">
          <div className="container-fluid">
            <div className="row">
              <div className="col-4">
                <div className="site-logo">
                  <a href="/">
                    <img
                      src="/assets/images/site-logo.png"
                      className="logo-header"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-8">
                <div className="main-mobile-buttons">
                  <button
                    className="toggle"
                    onClick={() => this.setShowHeader(true)}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                </div>
                <div
                  className={
                    showHeader
                      ? 'inner_content mobile_options'
                      : 'inner_content'
                  }
                >
                  <img
                    className="cross"
                    src="/assets/images/delete-sign.png"
                    alt="cross"
                    onClick={() => this.setShowHeader(false)}
                  />
                  <div className="button-sec">
                    <div className="btnns">
                      {user_info ? (
                        <>
                          <Link to="/logout" className="sign-in">
                            <img
                              src="/assets/images/log-out.png"
                              alt="Sign-out"
                            />
                            Sign Out
                          </Link>
                        </>
                      ) : (
                        <Link to="/login" className="sign-in">
                          <img src="/assets/images/login.png" alt="Sign-in" />
                          Sign In
                        </Link>
                      )}
                      <Link to="/cart" className="cart">
                        <img src="/assets/images/cart.png" alt="cart" />
                        Cart
                      </Link>
                      <Link to="/wishlist" className="whish-list">
                        <img src="/assets/images/wishlist.png" alt="cart" />
                        Wish
                      </Link>
                    </div>
                    <div className="buy-btn">
                      <a className="btn btn-primary" href="/how-to-buy">
                        How To Buy
                      </a>
                    </div>
                  </div>
                  {/* <div className="dropdown">
                    <div className="select">
                      <select className="select-styled">
                        <option
                          className="option"
                          value="http://www.taobao.com/"
                        >
                          http://www.taobao.com/
                        </option>
                        <option value="http://www.taobao.com/">
                          http://www.taobao.com/
                        </option>
                        <option value="http://www.taobao.com/">
                          http://www.taobao.com/
                        </option>
                        <option value="http://www.taobao.com/">
                          http://www.taobao.com/
                        </option>
                      </select>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </header>

        {/* <header
          className="header-main mobile_header "
        >
          <div className="container-fluid">
            <div className="row">
            <div className="col-4">
                <div className="site-logo">
                  <a href="/">
                    <img
                      src="/assets/images/site-logo.png"
                      className="logo-header"
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-8">
              <div className="main-mobile-buttons">
                  <button
                    className="toggle"
                    onClick={() => this.setShowHeader(true)}
                  >
                    <i className="fa fa-bars"></i>
                  </button>
                </div>
                <div
                  className={
                    showHeader
                      ? 'inner_content mobile_options'
                      : 'inner_content'
                  }
                >
                  <div className="">
                    <div className="">
                      <div className="">
                        <h3
                          className="account"
                          data-test="modal-drawer-heading"
                          tabIndex="-1"
                        >
                          Account
                        </h3>
                        <div className="styles__IconButtonCloseWrapper-sc-1uliqpu-6 dbMvVR">
                          <button
                            onClick={() => this.setShowDashboard(false)}
                            className="cross-button"
                          >
                            <svg
                              width="16px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              data-icon-name="XMark"
                              focusable="false"
                              role="presentation"
                              className="style__IconWrapper-sc-7xjkag-0 jYXcWu"
                            >
                              <path
                                d="M22 3.4 13.4 12l8.6 8.6-1.4 1.4-8.6-8.6L3.4 22 2 20.6l8.6-8.6L2 3.4 3.4 2l8.6 8.6L20.6 2 22 3.4Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-content">
                    <ul className="dashboard-content-ul">
                      <li>
                        <Link to={'/account'}>
                          <span className="">
                            <span>Edit Profile</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/completed-orders'}>
                          <span className="">
                            <span>Completed Orders</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/in-progress-orders'}>
                          <span className="">
                            <span>In-Progress Orders</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/complaints'}>
                          <span className="">
                            <span>Complaints</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <LogoutButton />
                      </li>
                    </ul>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </header> */}

        <header
          className={
            showHeaderDashboard ? 'dashboard dashboard-active' : 'dashboard'
          }
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-8">
                <div
                  className={
                    showHeaderDashboard
                      ? 'dashboard_content dashboard_options'
                      : 'dashboard_content'
                  }
                >
                  <div className="">
                    <div className="">
                      <div className="">
                        <h3
                          className="account"
                          data-test="modal-drawer-heading"
                          tabIndex="-1"
                        >
                          {username}
                        </h3>
                        <div className="styles__IconButtonCloseWrapper-sc-1uliqpu-6 dbMvVR">
                          <button
                            onClick={() => this.setShowDashboard(false)}
                            className="cross-button"
                          >
                            <svg
                              width="16px"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                              data-icon-name="XMark"
                              focusable="false"
                              role="presentation"
                              className="style__IconWrapper-sc-7xjkag-0 jYXcWu"
                            >
                              <path
                                d="M22 3.4 13.4 12l8.6 8.6-1.4 1.4-8.6-8.6L3.4 22 2 20.6l8.6-8.6L2 3.4 3.4 2l8.6 8.6L20.6 2 22 3.4Z"
                                fill="#fff"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="dashboard-content">
                    <ul className="dashboard-content-ul">
                      <li>
                        <Link to={'/account'}>
                          <span className="">
                            <span>Edit Profile</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/completed-orders'}>
                          <span className="">
                            <span>Completed Orders</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/in-progress-orders'}>
                          <span className="">
                            <span>In-Progress Orders</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <Link to={'/complaints'}>
                          <span className="">
                            <span>Complaints</span>
                          </span>
                        </Link>
                      </li>
                      <li>
                        <LogoutButton />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="header-bottom">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-md-6">
                <div className="heading">
                  <h3>Buy wholesale or retail from China</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    Auth: state.Auth
  };
}
const mapDispatchToProps = {
  onCart: userActions.GetCart,
  onWishlist: userActions.GetWishlist,
  getProduct: productActions.Getproduct
};
export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true
})(LoginHeader);
