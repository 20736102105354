import React from 'react';
import { connect } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as userActions from '../../../actions/index';
import LoginHeader from '../../../components/LoginHeader';
import LoginFooter from '../../../components/LoginFooter';
import Loader from '../../../components/Utility/Loader';
import ReactPaginate from 'react-paginate';
import TranslateComponent from '../../Utility/TranslateComponent';
import getSymbolFromCurrency from 'currency-symbol-map';
import axios from './../../../axios'; // Ensure axios is imported

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: {
        title: 'Title'
      }
    }
  },
  lng: 'en',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false
  }
});

class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      zero_product: false,
      currentPage: 1,
      product_details: [],
      header_search_loader: false,
      ApiCurrency: getSymbolFromCurrency('RMB'),
      ConversionCurrency: getSymbolFromCurrency('NGN'),
      currencyRate: null,
      searchKeyword: null,
      searchInput: '',
      pages: [1]
    };
  }

  componentWillMount() {
    if (this.props.location?.state?.results?.data?.length > 0) {
      this.setState({
        product_details: this.props.location?.state?.results,
        currencyRate: localStorage.getItem('currencyRate'),
        searchKeyword: this.props.location.search.substring(1)
      });
    } else {
      this.setState({ loader: true });
      window.scrollTo(0, 0);
      var search = this.props.location.search.substring(1);
      if (!search) {
        search = 'latest';
      }
      if (search) {
        this.props
          .onGetProduct(search)
          .then((response) => {
            const productData = response?.data?.response?.data?.data;
            console.log('###response', response);

            if (response.data.success === true) {
              this.setState({ loader: false });

              // Update the URL with the search keyword
              window.history.pushState(
                null,
                null,
                `/product-list/search?${search.trim()}`
              );

              let product_details = [];
              // Check if productData exists and has items
              if (productData && productData.length > 0) {
                product_details = productData;
              } else if (response.data?.item) {
                // Handle case where there is an item in the response but no productData
                product_details = [response.data.item];
              } else {
                // If no products are found, set zero_product state
                this.setState({ zero_product: true });
              }

              // Update the state with product details, currency rate, and search keyword
              this.setState({
                zero_product: product_details.length === 0,
                product_details: product_details,
                currencyRate: localStorage.getItem('currencyRate'),
                searchKeyword: this.props.location.search.substring(1) // Extract search query
              });
            } else {
              // Handle case where API response is not successful
              this.setState({ loader: false });
              toast.error('No results found with this text', {
                position: toast.POSITION.TOP_RIGHT
              });
            }
          })
          .catch((error) => {
            // Handle any errors during the API call
            console.error('Error fetching product data:', error);
            this.setState({ loader: false });
            toast.error('An error occurred while fetching product data', {
              position: toast.POSITION.TOP_RIGHT
            });
          });
      } else {
        console.log('search', search);
        toast.error('Please add some text or link to search items', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const searchKeyword = params.get('search') || '';
    const page = parseInt(params.get('page')) || 1;

    this.setState({
      searchKeyword: searchKeyword,
      currentPage: page
    });

    if (searchKeyword) {
      this.GetProducts(page, searchKeyword);
    } else {
      console.log('searchKeyword'.searchKeyword);
      console.log('params'.params);
    }
  }

  GetProducts = (pageNo, product_search) => {
    if (!product_search) {
      toast.error('Please add some text or link to search items', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    this.props
      .onGetProduct(product_search, pageNo)
      .then((response) => {
        if (response?.data?.success) {
          const productData = response?.data?.response?.data?.data || [];
          this.setState({
            zero_product: productData.length === 0,
            product_details: productData,
            currencyRate: localStorage.getItem('currencyRate'),
            loader: false
          });
        } else {
          toast.error('No results found with this text', {
            position: toast.POSITION.TOP_RIGHT
          });
          this.setState({ loader: false }); // Hide the loader if no results found
        }
      })
      .catch((error) => {
        console.error('Error fetching products:', error);
        toast.error('An error occurred while fetching products.', {
          position: toast.POSITION.TOP_RIGHT
        });
        this.setState({ loader: false }); // Hide the loader if an error occurs
      });
  };

  handlePageChange = (direction) => {
    const { currentPage, searchKeyword } = this.state;

    // Determine the next page based on the direction
    const nextPage = direction === 'next' ? currentPage + 1 : currentPage - 1;

    // Ensure the page number doesn't go below 1
    if (nextPage < 1) return;

    // Set the loader to true to show the loader while fetching the new page
    this.setState({ loader: true, currentPage: nextPage }, () => {
      this.GetProducts(nextPage, searchKeyword);

      // Update the URL
      const queryString = `search=${searchKeyword}&page=${nextPage}`;
      window.history.pushState(null, null, `/product-list?${queryString}`);

      // Scroll to the top
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    });
  };

  fetchData = (page) => {
    console.log(`Fetching data for page: ${page}`);
    // Call your API or data-fetching function here

    return page;
  };

  renderPagination = () => {
    const { pages, currentPage } = this.state;

    return pages.map((page) => (
      <button
        key={page}
        className={`btn mx-0.5 ${
          page === currentPage ? 'active' : 'page-link'
        }`}
        onClick={() =>
          this.setState({ currentPage: page }, () => this.fetchData(page))
        }
      >
        {page}
      </button>
    ));
  };

  SearchHandler = async (value) => {
    if (value === '') {
      toast.error('Keyword is required to search', {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    const keyword = value || 'iphone';

    this.setState({
      search_loader: true,
      currentPage: 1
    });

    try {
      this.setState({ loader: true });
      const response = await axios.get(`/item_search?keyword=${keyword}`);
      this.setState({ loader: false });
      console.log('response', response.data.response.data);

      if (response.data) {
        this.setState({
          is_redirect: true,
          redirect_url: `/product-list/search?keyword=${keyword}&page=1`,
          redirect_state: { results: response?.data?.response?.data?.data },
          currentPage: 1,
          product_details: response?.data?.response?.data?.data,
          searchKeyword: keyword
        });
      } else {
        toast.error('No Result Found', {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    } catch (error) {
      console.error('Error occurred during search:', error);
      toast.error('An error occurred during search', {
        position: toast.POSITION.TOP_RIGHT
      });
    } finally {
      this.setState({ search_loader: false });
    }
  };

  handleSearchInputChange = (event) => {
    this.setState({ searchInput: event.target.value });
  };

  render() {
    const {
      product_details,
      zero_product,
      ApiCurrency,
      currencyRate,
      ConversionCurrency,
      searchKeyword,
      searchInput
    } = this.state;

    const currentPageData = product_details;

    console.log("###################### CUrrency:", currencyRate)

    return (
      <React.Fragment>
        {this.state.is_redirect ? (
          <Navigate
            to={this.state.redirect_url}
            state={this.state.redirect_state}
          />
        ) : null}
        {this.state.loader ? <Loader /> : null}
        {this.props.loader ? <Loader /> : null}
        <div className="">
          <div className="row my-8 searchDetails">
            <div className="col-12 position-relative">
              <input
                type="text"
                value={searchInput}
                onChange={this.handleSearchInputChange}
                placeholder="Search for categories..."
                className="form-control pe-5" // Add padding to the right to prevent overlap with the icon
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.SearchHandler(searchInput);
                  }
                }}
              />
              <i
                className="fa fa-search position-absolute top-50 end-0 translate-middle-y me-3 text-muted"
                style={{ cursor: 'pointer', padding: '20px' }}
                onClick={() => this.SearchHandler(searchInput)} // Optional: Add click functionality
              ></i>
            </div>
          </div>

          <div className="category-sec">
            <div className="container-fluid">
              <div className="row" id="currentPageData">
                {zero_product &&
                  'NO RESULT FOUND PLEASE TRY AGAIN WITH OTHER KEYWORDS'}
                {currentPageData.map((item, product_key) => (
                  <div className="col-lg-4 col-md-6" key={product_key}>
                    <div className="product-card">
                      <Link
                        to={`/product-detail/search?${item?.item_id}`}
                        state={{
                          product_detail: item,
                          searchKeyword,
                          currencyRate
                        }}
                        className="product-card__link"
                      >
                        {/* Product Image */}
                        <div className="product-card__image-container">
                          <img
                            src={item?.main_image_url}
                            alt={item?.title || 'Product Image'}
                            className="product-card__image"
                          />
                        </div>

                        {/* Product Details */}
                        <div className="product-card__content">
                          {/* Product Title */}
                          <h2 className="product-card__title">
                            <TranslateComponent
                              content={item?.title}
                              targetLanguage="en"
                            />
                          </h2>

                          {/* Product Price */}
                          <div className="product-card__price">
                            {/* <span className="product-card__currency">
                              {ApiCurrency}
                            </span> */}
                            {/* <span className="product-card__amount">
                              {parseFloat(item?.price).toFixed(2)}
                            </span> */}
                            <span className="#product-card__conversion card__amount">
                              {/* ≈*/} {ConversionCurrency} 
                              {parseFloat(item?.price * currencyRate).toFixed(
                                2
                              )}
                            </span>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))}
                {/* <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={this.handlePageChange}
                                    pageRangeDisplayed={3}
                                    marginPagesDisplayed={2}
                                    pageCount={totalPages}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                /> */}
                <div className="pagination">
                  <button
                    className="btn page-link"
                    onClick={() => this.handlePageChange('prev')}
                    disabled={this.state.currentPage === 1}
                  >
                    &lt; Previous
                  </button>

                  {this.renderPagination()}

                  <button
                    className="btn page-link"
                    onClick={() => this.handlePageChange('next')}
                  >
                    Next &gt;
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.Auth.loader,
    error: state.Auth.error
  };
}
const mapDispatchToProps = {
  onGetProduct: userActions.Getproduct
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
