import { Navigate } from 'react-router-dom';
import * as actions from './actions';

const ProtectedRoute = ({ isLoggedIn, children }) => {
  const isAuthorized = actions.isAuthorize();
  const isSignInVerificationRoute =
    window.location.pathname === '/SignInVerification';

  // Redirect to sign-in if the user is not authorized and not on the SignInVerification route
  if (!isAuthorized && !isSignInVerificationRoute) {
    return <Navigate to="/login" replace />;
  }

  // If authorized or on SignInVerification route, render children
  return children;
};

export default ProtectedRoute;
