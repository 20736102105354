// Layout.js
import React, { useEffect } from 'react';
import Header from './../LoginHeader';
import Footer from './../LoginFooter';
import { Outlet } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const Layout = ({ children }) => {
  const { logout } = useAuth0();
  useEffect(() => {
    if (!localStorage.getItem('shiporfly_authtoken')) {
      logout();
    }
  }, []);
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
