import React from "react";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../actions/orders";
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import DataTable from 'react-data-table-component';
import "react-step-progress-bar/styles.css";
import { ProgressBar, Step } from "react-step-progress-bar";
import noOrder from "../../assets/static images/noOrder.jpg";
import Moment from 'react-moment';

class InProgressOrder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            heading: 'In Progress Order',
            completedOrders: [],
            viewOrder: false,
            viewOrderData: null,
            orderPercentage: 10,
            progressSpan: {
                bottom: '60px',
                top: '-37px'
            },
            illustrationDiv: {
                height: '400px',
                width: '400px',
                margin: 'auto',
            },
            illustration: {
                height: '100%',
                width: '100%',
            },
            imageCard: {
                height: '470px'
            },
            imgCard: {
                height: '100%',
                width: '100%',
                objectFit: 'cover'
            }
        };
    }

    componentDidMount() {
        document.body.classList.remove('dashboard-open');
        let form_data = new FormData();
        if (this.props.location === "View-orders") {
            this.setState({
                heading: "Order Details"
            });
            let id = window.location.search.replace('?', '');
            form_data.append('id', atob(id));
            form_data.append('status', 'delivered');
        } else {
            form_data.append('status', 'success');
        }
        this.props.getInProgressOrders(form_data).then((response) => {
            if (response.success === true) {
                this.setState({
                    completedOrders: response?.response,
                });
            }
        });
    }

    viewOrderDetails = (data) => {
        this.setState({ viewOrder: true, viewOrderData: data });
    }

    render() {
        const { heading, completedOrders } = this.state;
        return (
            <>
                <React.Fragment>
                    <div className="">
                        
                        <div className="checkout-template">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="title">
                                            <h1 className="d-flex">{heading}</h1>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        {completedOrders?.length === 0 &&
                                            <div className="empty-cart text-center mb-3">
                                                <div style={this.state.illustrationDiv}>
                                                    <img style={this.state.illustration} src={noOrder} alt="no order found......" />
                                                </div>
                                                <span className="mt-0">No Order yet</span>
                                            </div>
                                        }
                                        {completedOrders.map((item) => (
                                            <div className="container" key={item.id}>
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="order-progress-bar">
                                                            <p className="order-number">Order Number # {item.id}</p>
                                                            <ProgressBar percent={item.track_orders.length === 0 ? 75 : item.progress}
                                                                filledBackground="#03ad3c"
                                                                height="3px">
                                                                <Step transition="scale" className="steps">
                                                                    {({ accomplished }) => (
                                                                        <>
                                                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                            <span className="stepspan text-center">Order has been placed</span>
                                                                        </>
                                                                    )}
                                                                </Step>
                                                                <Step transition="scale">
                                                                    {({ accomplished }) => (
                                                                        <>
                                                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                            <span className="stepspan text-center" style={this.state.progressSpan}>Payment has been confirmed</span>
                                                                        </>
                                                                    )}
                                                                </Step>
                                                                <Step transition="scale">
                                                                    {({ accomplished }) => (
                                                                        <>
                                                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                            <span className="stepspan text-center">Purchase has been initiated</span>
                                                                        </>
                                                                    )}
                                                                </Step>
                                                                <Step transition="scale">
                                                                    {({ accomplished }) => (
                                                                        <>
                                                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                            <span className="stepspan text-center" style={this.state.progressSpan}>Purchase has been completed</span>
                                                                        </>
                                                                    )}
                                                                </Step>
                                                                {item.track_orders.map((trackItem, itemIndex) => (
                                                                    <Step transition="scale" key={itemIndex}>
                                                                        {({ accomplished }) => (
                                                                            <>
                                                                                <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                                {(itemIndex + 1) % 2 === 0 ? (
                                                                                    <span className="stepspan text-center" style={this.state.progressSpan}>{trackItem.location}</span>
                                                                                ) : (
                                                                                    <span className="stepspan text-center">{trackItem.location}</span>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </Step>
                                                                ))}
                                                                <Step transition="scale">
                                                                    {({ accomplished }) => (
                                                                        <>
                                                                            <div className={`indexedStep ${accomplished ? "accomplished" : null}`} />
                                                                            <span className="stepspan text-center">Order is completed</span>
                                                                        </>
                                                                    )}
                                                                </Step>
                                                            </ProgressBar>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="mx-2 mb-5">
                                                            <div className="row d-flex justify-content-center align-items-center h-100">
                                                                <div className="col-lg-6 col-md-9">
                                                                    <div className="card">
                                                                        <div className="card-body" style={this.state.imageCard}>
                                                                            <img src={item?.order_items[0]?.productJson?.item?.pic_url} style={this.state.imgCard} className="card-img-top" alt="..." />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-8 col-xl-6">
                                                                    <div className="card border-top border-bottom border-3">
                                                                        <div className="card-body p-5">
                                                                            <p className="lead fw-bold mb-3">Purchase Receipt</p>
                                                                            <div className="mx-n5 py-2">
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-lg-8">
                                                                                        <p className="small text-muted mb-1 text-start">Date</p>
                                                                                        <p className="text-start"><Moment format='LL'>{item.created_at}</Moment></p>
                                                                                    </div>
                                                                                    <div className="col-md-4 col-lg-3">
                                                                                        <p className="small text-muted mb-1">Order No.</p>
                                                                                        <p>#{item.id}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mx-n5 py-4">
                                                                                <div className="row mb-4">
                                                                                    <div className="col-md-8 col-lg-8">
                                                                                        <p className="mb-0 text-start">Shipping</p>
                                                                                    </div>
                                                                                    <div className="col-md-4 col-lg-3">
                                                                                        <p className="mb-0">£{item.shipping_charge}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="row">
                                                                                    <div className="col-md-8 col-lg-8">
                                                                                        <p className="text-start">{item?.order_items[0]?.productJson?.item?.title}</p>
                                                                                    </div>
                                                                                    <div className="col-md-4 col-lg-3">
                                                                                        <p>${item.totalprice}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <p className="lead fw-bold mb-3">Address</p>
                                                                            <div className="mx-n5 py-3">
                                                                                <p className="text-center mb-2">{item.billing_email}</p>
                                                                                <div className="row mb-4 text-center">
                                                                                    <h5>{item.billing_first_name} {item.billing_last_name}, {item.billing_address}</h5>
                                                                                    <h6>{item.billing_country},{item.billing_state}({item.billing_zip})</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </React.Fragment>
            </>
        );
    }
}

function mapStateToProps(state) {
    return {
        loader: state.Auth.loader,
        error: state.Auth.error,
    };
}

const mapDispatchToProps = {
    getInProgressOrders: action.GetInProgressOrders,
};

export default connect(mapStateToProps, mapDispatchToProps)(InProgressOrder);
