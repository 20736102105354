import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loader from '../../components/Utility/Loader';

const SignIn = () => {
    const { loginWithRedirect, isLoading, error } = useAuth0();

    useEffect(() => {
        loginWithRedirect();
    }, [loginWithRedirect]);


    console.log(error, "Login failed")

    if (error) {
        // Handle the error here, for example, showing a message that the account is blocked
        return <div>Your account has been blocked.</div>;
    }

    return (
        <div>
            {isLoading ? <Loader /> : null}
        </div>
    );
};

export default SignIn;

