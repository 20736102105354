import axios from "axios";
import * as actionType from "../constants/actionTypes";

export const complaint = (form_data) => {
    return () => {
        return axios({
            method: "post",
            url: `${actionType.environment_url}/complaint`,
            data: form_data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
}
export const my_complaints = () => {
    return () => {
        return axios({
            method: "get",
            url: `${actionType.environment_url}/my_complaints`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
}
export const getComplaints = () => {
    return () => {
        return axios({
            method: "get",
            url: `${actionType.environment_url}/complaints`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
}
export const viewComplaintAdmin = (id)=>{
    return () => {
        return axios({
            method: "post",
            url: `${actionType.environment_url}/view-complaints/${id}`,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
            .then(function (response) {
                return response.data;
            })
            .catch(function (error) {
                return error;
            });
    };
}
    
export const replyComplaints = (form_data)=>{
    return () => {
        return axios({
            method: "post",
            url: `${actionType.environment_url}/reply-complaint`,
            data: form_data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    };
}
export const complaintResoved = (form_data)=>{
    return () => {
        return axios({
            method: "post",
            url: `${actionType.environment_url}/complaint-status-update`,
            data: form_data,
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("shiporfly_super_admin_authtoken")}`,
            },
        })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            return error;
        });
    };
}