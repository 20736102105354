import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import AppRouters from './Routers';
import { useDispatch } from 'react-redux';
import { GetCurrencyRate } from './actions/account';

function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCurrencyRate());
  }, [dispatch]);

  return <AppRouters />;
}

export default App;
