
import LoginHeader from "../../components/LoginHeader";
import LoginFooter from "../../components/LoginFooter";
import SigninVerifyComp from "../../containers/Auth/signinverifycomp";

const SignInVerification = () => {

    return (
        <>
     
        <SigninVerifyComp />
            <section className="bg-white w-full">
                <div className="flex bg-white w-full h-[calc(100vh_-_69px)] relative z-0">
                    <div className="bg-[#FFF6F0] w-full h-full max-w-[50%] p-[50px] md:flex hidden items-center justify-center">
                        {/* <img src="/assets/images/sign-in.png" alt="" /> */}
                    </div>
                    <div className="bg-white w-full h-full md:max-w-[50%] max-w-[100%] p-[50px] md:px-[50px] px-[15px] flex items-center justify-center overflow-auto">
                    </div>
                </div>
            </section>
           
        </>
    );
};

export default SignInVerification;
